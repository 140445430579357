import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Light.otf');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Regular.otf');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Medium.otf');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Bold.otf');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  /* #root {
    min-width: 1024px;
  } */

  
  body {
    margin: 0;
    font-family:  'Matter', Helvetica, sans-serif;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: 0;
  }

  button:focus, input:focus {
    outline: 0;
  }

  button, input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    font-family:  'Matter', Helvetica, sans-serif;
  }

  .direction-right > .fade-enter {
    opacity: 0;
    transform: translateX(-10%);
  }
  .direction-right > .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .direction-right > .fade-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .direction-right > .fade-exit-active {
    opacity: 0;
    transform: translateX(10%);
  }
  .direction-right > .fade-enter-active,
  .direction-right > .fade-exit-active {
    transition: opacity 200ms ease, transform 200ms ease;
  }

  .direction-left > .fade-enter {
    opacity: 0;
    transform: translateX(10%);
  }
  .direction-left > .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .direction-left > .fade-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .direction-left > .fade-exit-active {
    opacity: 0;
    transform: translateX(-10%);
  }
  .direction-left > .fade-enter-active,
  .direction-left > .fade-exit-active {
    transition: opacity 200ms ease, transform 200ms ease;
  }
`
