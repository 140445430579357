import * as React from 'react'
import styled from 'styled-components'
import { Heading5 } from './Typography'

interface Props {
  header: string
  items: string[]
}

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  position: relative;
  padding-left: 8px;
  font-size: 13px;
  line-height: 16px;
  color: #606060;

  margin-top: 8px;

  &:before {
    content: '·';
    position: absolute;
    left: 0;
  }
`

const List: React.FC<Props> = ({ header, items }) => (
  <>
    <Heading5>{header}</Heading5>
    <Ul>
      {items.map(item => (
        <Li key={item}>{item}</Li>
      ))}
    </Ul>
  </>
)

export default List
