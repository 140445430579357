import React from 'react'
import { colors } from '../../styles'
import { SvgTypes } from './types'

const ArrowRight: React.SFC<SvgTypes> = ({
  fill = colors.white,
  width = 17,
  height = 15,
}): React.ReactElement => {
  return (
    <svg width={width} height={height}>
      <path
        d="M1 6.5a1 1 0 000 2v-2zm15.707 1.707a1 1 0 000-1.414L10.343.429a1 1 0 10-1.414 1.414L14.586 7.5l-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM1 8.5h15v-2H1v2z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default ArrowRight
