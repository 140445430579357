import React from 'react'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import Box from '../../../components/Box'
import Pagination from '../../../components/SwipeablePagination/Pagination'
import { mediaQuery } from '../../../styles'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const InfoHeader = styled.h5`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
`

const InfoDescription = styled.p`
  font-size: 16px;
  line-height: 1.4;
`

const Relative = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  width: 100%;
`

const Image = styled.img`
  height: 534px;

  ${mediaQuery.tablet} {
    height: 450px;
  }

  ${mediaQuery.medium} {
    height: 534px;
  }
`

const ContainerPagination = styled.div`
  display: block;
  width: 100%;

  ${mediaQuery.tablet} {
    display: none;
  }
`

const ContainerPositionImage = styled.div`
  position: relative;
  top: 0px;

  ${mediaQuery.tablet} {
    top: 12px;
  }
`

const ContainerPositionCards = styled.div`
  position: relative;
  top: 0px;
  width: 100%;

  ${mediaQuery.tablet} {
    top: 10px;
  }
`

const slideshow = [
  {
    header: 'Launch a paid fan club',
    description:
      'Give your fans what they really want: win exclusive experiences with you, earn special badges, and meet other fans',
  },
  {
    header: 'Understand your fans deeply',
    description:
      'Unlock proprietary insights on your fanbase to grow your personal brand and company on a massive scale.',
  },
  {
    header: 'Build a community, not a number',
    description:
      '1000 superfans > 10 million followers. Your superfans are the most valuable people in your entire community.',
  },
]

const AuthSlideshow: React.FC = (): React.ReactElement => {
  const [currentStep, setCurrentStep] = React.useState<number>(0)

  const handleOnChange = (index: number) => {
    setCurrentStep(index)
  }

  return (
    <Relative>
      <ContainerPositionImage>
        <Image src="/onboardingDevice.png" alt="Zebra App" />
      </ContainerPositionImage>

      <ContainerPositionCards>
        <AutoPlaySwipeableViews
          index={currentStep}
          onChangeIndex={handleOnChange}
          style={{ width: '100%' }}
        >
          {Object.values(slideshow).map(({ header, description }) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                className="direction-right"
                overflow="hidden"
                width="100%"
                key={header}
              >
                <ContainerInfo>
                  <InfoHeader>{header}</InfoHeader>
                  <InfoDescription>{description}</InfoDescription>
                </ContainerInfo>
              </Box>
            )
          })}
        </AutoPlaySwipeableViews>
      </ContainerPositionCards>

      <ContainerPagination>
        <Pagination
          dots={3}
          index={currentStep}
          onChangeIndex={handleOnChange}
        />
      </ContainerPagination>
    </Relative>
  )
}

export default AuthSlideshow
