"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Roles;
(function (Roles) {
    Roles["USER"] = "USER";
    Roles["CUSTOMER"] = "CUSTOMER";
    Roles["ORG_ADMIN"] = "ORG_ADMIN";
    Roles["ADMIN"] = "ADMIN";
    Roles["ORG_VIEWER"] = "ORG_VIEWER";
})(Roles = exports.Roles || (exports.Roles = {}));
var OptionOrdering;
(function (OptionOrdering) {
    OptionOrdering["TOP"] = "TOP";
    OptionOrdering["BOTTOM"] = "BOTTOM";
})(OptionOrdering = exports.OptionOrdering || (exports.OptionOrdering = {}));
var VerbType;
(function (VerbType) {
    VerbType["ANSWER"] = "ANSWER";
    VerbType["INSTALL"] = "INSTALL";
    VerbType["REFER"] = "REFER";
    VerbType["INTERVIEW"] = "INTERVIEW";
    VerbType["SHARE"] = "SHARE";
    VerbType["ENRICH"] = "ENRICH";
})(VerbType = exports.VerbType || (exports.VerbType = {}));
var AnswerType;
(function (AnswerType) {
    AnswerType["OPTION"] = "OPTION";
    AnswerType["TEXT"] = "TEXT";
    AnswerType["URL"] = "URL";
    AnswerType["IMAGE"] = "IMAGE";
    AnswerType["VIDEO"] = "VIDEO";
    AnswerType["AUDIO"] = "AUDIO";
    AnswerType["BOOLEAN"] = "BOOLEAN";
    AnswerType["RATING"] = "RATING";
    AnswerType["SKIP"] = "SKIP";
})(AnswerType = exports.AnswerType || (exports.AnswerType = {}));
var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["NOT_STARTED"] = "NOT_STARTED";
    ReviewStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ReviewStatus["IN_REVIEW"] = "IN_REVIEW";
    ReviewStatus["REJECTED"] = "REJECTED";
    ReviewStatus["COMPLETED"] = "COMPLETED";
    ReviewStatus["CANCELLED"] = "CANCELLED";
    ReviewStatus["EXPIRED"] = "EXPIRED";
})(ReviewStatus = exports.ReviewStatus || (exports.ReviewStatus = {}));
var CommunityMembershipStatus;
(function (CommunityMembershipStatus) {
    CommunityMembershipStatus["RECOMMENDED"] = "RECOMMENDED";
    CommunityMembershipStatus["APPLIED"] = "APPLIED";
    CommunityMembershipStatus["JOINED"] = "JOINED";
    CommunityMembershipStatus["LEFT"] = "LEFT";
    CommunityMembershipStatus["APPLYING"] = "APPLYING";
})(CommunityMembershipStatus = exports.CommunityMembershipStatus || (exports.CommunityMembershipStatus = {}));
var SocialProvider;
(function (SocialProvider) {
    SocialProvider["SNAPCHAT"] = "SNAPCHAT";
    SocialProvider["INSTAGRAM"] = "INSTAGRAM";
    SocialProvider["OTHER"] = "OTHER";
})(SocialProvider = exports.SocialProvider || (exports.SocialProvider = {}));
var EntryType;
(function (EntryType) {
    EntryType["CREDIT"] = "CREDIT";
    EntryType["DEBIT"] = "DEBIT";
})(EntryType = exports.EntryType || (exports.EntryType = {}));
var CashOutStatus;
(function (CashOutStatus) {
    CashOutStatus["PENDING"] = "PENDING";
    CashOutStatus["APPROVED"] = "APPROVED";
    CashOutStatus["DENIED"] = "DENIED";
})(CashOutStatus = exports.CashOutStatus || (exports.CashOutStatus = {}));
var AnchorType;
(function (AnchorType) {
    AnchorType["IMAGE"] = "IMAGE";
    AnchorType["VIDEO"] = "VIDEO";
    AnchorType["AUDIO"] = "AUDIO";
})(AnchorType = exports.AnchorType || (exports.AnchorType = {}));
var DeviceType;
(function (DeviceType) {
    DeviceType["IOS"] = "IOS";
    DeviceType["ANDROID"] = "ANDROID";
    DeviceType["WEB"] = "WEB";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
var ProfileGender;
(function (ProfileGender) {
    ProfileGender["FEMALE"] = "female";
    ProfileGender["MALE"] = "male";
    ProfileGender["TRANS"] = "trans";
    ProfileGender["QUEER"] = "queer";
    ProfileGender["INTERSEX"] = "intersex";
    ProfileGender["FLUID"] = "fluid";
    ProfileGender["NOT_SPECIFIED"] = "not-specified";
})(ProfileGender = exports.ProfileGender || (exports.ProfileGender = {}));
var ProfilePronouns;
(function (ProfilePronouns) {
    ProfilePronouns["SHE"] = "SHE";
    ProfilePronouns["HE"] = "HE";
    ProfilePronouns["THEY"] = "THEY";
    ProfilePronouns["ZE"] = "ZE";
    ProfilePronouns["XE"] = "XE";
    ProfilePronouns["NOT_SPECIFIED"] = "not-specified";
})(ProfilePronouns = exports.ProfilePronouns || (exports.ProfilePronouns = {}));
var ProfileEthnicity;
(function (ProfileEthnicity) {
    ProfileEthnicity["BLACK"] = "BLACK";
    ProfileEthnicity["WHITE"] = "WHITE";
    ProfileEthnicity["HISPANIC"] = "HISPANIC";
    ProfileEthnicity["NATIVE_AMERICAN"] = "NATIVE_AMERICAN";
    ProfileEthnicity["ASIAN"] = "ASIAN";
    ProfileEthnicity["PACIFIC_ISLANDER"] = "PACIFIC_ISLANDER";
    ProfileEthnicity["OTHER"] = "OTHER";
    ProfileEthnicity["NOT_SPECIFIED"] = "NOT_SPECIFIED";
})(ProfileEthnicity = exports.ProfileEthnicity || (exports.ProfileEthnicity = {}));
var ProfileBirthSex;
(function (ProfileBirthSex) {
    ProfileBirthSex["FEMALE"] = "female";
    ProfileBirthSex["MALE"] = "male";
    ProfileBirthSex["INTERSEX"] = "intersex";
    ProfileBirthSex["NOT_SPECIFIED"] = "not-specified";
})(ProfileBirthSex = exports.ProfileBirthSex || (exports.ProfileBirthSex = {}));
var ProfileOrientation;
(function (ProfileOrientation) {
    ProfileOrientation["STRAIGHT"] = "straight";
    ProfileOrientation["GAY"] = "gay";
    ProfileOrientation["LESBIAN"] = "lesbian";
    ProfileOrientation["PAN"] = "pan";
    ProfileOrientation["BISEXUAL"] = "bisexual";
    ProfileOrientation["ASEXUAL"] = "asexual";
    ProfileOrientation["QUEER"] = "queer";
    ProfileOrientation["NOT_SPECIFIED"] = "not-specified";
})(ProfileOrientation = exports.ProfileOrientation || (exports.ProfileOrientation = {}));
var ProfileReligion;
(function (ProfileReligion) {
    ProfileReligion["RELIGIOUS_PRACTICING"] = "religious-practicing";
    ProfileReligion["RELIGIOUS_NOT_PRACTICING"] = "religious-not-practicing";
    ProfileReligion["SPIRITUAL"] = "spiritual";
    ProfileReligion["ATHEIST"] = "atheist";
    ProfileReligion["AGNOSTIC"] = "agnostic";
    ProfileReligion["NOT_SPECIFIED"] = "not-specified";
})(ProfileReligion = exports.ProfileReligion || (exports.ProfileReligion = {}));
var ProfilePolitics;
(function (ProfilePolitics) {
    ProfilePolitics["LEFT_LIBERAL"] = "left-liberal";
    ProfilePolitics["RIGHT_CONSERVATIVE"] = "right-conservative";
    ProfilePolitics["CENTRIST"] = "centrist";
    ProfilePolitics["LIBERTARIAN"] = "libertarian";
    ProfilePolitics["NONPARTISAN"] = "nonpartisan";
    ProfilePolitics["INDEPENDENT"] = "independent";
    ProfilePolitics["NOT_SPECIFIED"] = "not-specified";
})(ProfilePolitics = exports.ProfilePolitics || (exports.ProfilePolitics = {}));
var ProfileChildren;
(function (ProfileChildren) {
    ProfileChildren["ZERO"] = "0";
    ProfileChildren["ONE"] = "1";
    ProfileChildren["TWO"] = "2";
    ProfileChildren["THREE"] = "3";
    ProfileChildren["FOUR"] = "4";
    ProfileChildren["FIVE_AND_MORE"] = "5+";
    ProfileChildren["NOT_SPECIFIED"] = "not-specified";
})(ProfileChildren = exports.ProfileChildren || (exports.ProfileChildren = {}));
var ProfileLiving;
(function (ProfileLiving) {
    ProfileLiving["LIVING_WITH_PARENT_OR_GUARDIAN"] = "living-with-parent-or-guardian";
    ProfileLiving["STUDENT_DORM"] = "student-dorm";
    ProfileLiving["RENTING_ON_MY_OWN"] = "renting-on-my-own";
    ProfileLiving["RENTING_WITH_ROOMMATES"] = "renting-with-roommates";
    ProfileLiving["RENTING_WITH_SIGNIFICANT_OTHER"] = "renting-with-significant-other";
    ProfileLiving["OWN_AN_APARTMENT_OR_CONDO"] = "own-an-apartment-or-condo";
    ProfileLiving["OWN_A_HOUSE"] = "own-a-house";
    ProfileLiving["NOT_SPECIFIED"] = "not-specified";
})(ProfileLiving = exports.ProfileLiving || (exports.ProfileLiving = {}));
var ProfileRelationship;
(function (ProfileRelationship) {
    ProfileRelationship["SINGLE_AND_LOOKING"] = "single-and-looking";
    ProfileRelationship["SINGLE_AND_NOT_LOOKING"] = "single-and-not-looking";
    ProfileRelationship["ENGAGED"] = "engaged";
    ProfileRelationship["MARRIED"] = "married";
    ProfileRelationship["IN_RELATIONSHIP"] = "in-relationship";
    ProfileRelationship["ITS_COMPLICATED"] = "its-complicated";
    ProfileRelationship["SEPARATED"] = "separated";
    ProfileRelationship["IN_A_DOMESTIC_PARTNERSHIP"] = "in-a-domastic-partnership";
    ProfileRelationship["IN_AN_OPEN_RELATIONSHIP"] = "in-an-open-relationship";
    ProfileRelationship["WIDOWED"] = "widowed";
    ProfileRelationship["NOT_SPECIFIED"] = "not-specified";
})(ProfileRelationship = exports.ProfileRelationship || (exports.ProfileRelationship = {}));
var ProfilePregnant;
(function (ProfilePregnant) {
    ProfilePregnant["YES"] = "yes";
    ProfilePregnant["NO"] = "no";
    ProfilePregnant["EXPECTING"] = "expecting";
    ProfilePregnant["PARTNER_PREGNANT"] = "partner-pregnant";
    ProfilePregnant["PARTNER_EXPECTING"] = "partner-expecting";
    ProfilePregnant["NOT_SPECIFIED"] = "not-specified";
})(ProfilePregnant = exports.ProfilePregnant || (exports.ProfilePregnant = {}));
var ProfileHousehold;
(function (ProfileHousehold) {
    ProfileHousehold["ZERO"] = "0";
    ProfileHousehold["ONE"] = "1";
    ProfileHousehold["TWO"] = "2";
    ProfileHousehold["THREE"] = "3";
    ProfileHousehold["FOUR"] = "4";
    ProfileHousehold["FIVE"] = "5";
    ProfileHousehold["SIX"] = "6";
    ProfileHousehold["SEVEN"] = "7";
    ProfileHousehold["EIGHT"] = "8";
    ProfileHousehold["NINE"] = "9";
    ProfileHousehold["MORE_THAN_TEN"] = "10+";
    ProfileHousehold["NOT_SPECIFIED"] = "not-specified";
})(ProfileHousehold = exports.ProfileHousehold || (exports.ProfileHousehold = {}));
var ProfileEducation;
(function (ProfileEducation) {
    ProfileEducation["ELEMENTARY"] = "elementary-school";
    ProfileEducation["MIDDLE"] = "middle-school";
    ProfileEducation["HIGH"] = "high-school";
    ProfileEducation["COLLEGE_UNIVERSITY"] = "college-university";
    ProfileEducation["TRADE"] = "trade-school";
    ProfileEducation["GRADUATE"] = "graduate";
    ProfileEducation["NOT_SPECIFIED"] = "not-specified";
})(ProfileEducation = exports.ProfileEducation || (exports.ProfileEducation = {}));
var ProfileEmployment;
(function (ProfileEmployment) {
    ProfileEmployment["EMPLOYED_FULL"] = "employed-full-time";
    ProfileEmployment["EMPLOYED_PART"] = "employed-part-time";
    ProfileEmployment["UNEMPLOYED_LOOKING"] = "unemployed-looking";
    ProfileEmployment["UNEMPLOYED_NOT_LOOKING"] = "unemployed-not-looking";
    ProfileEmployment["INTERN"] = "intern";
    ProfileEmployment["CONTRACTOR"] = "contractor-gig";
    ProfileEmployment["SELF_EMPLOYED"] = "self-employed";
    ProfileEmployment["RETIRED"] = "retired";
    ProfileEmployment["NOT_SPECIFIED"] = "not-specified";
})(ProfileEmployment = exports.ProfileEmployment || (exports.ProfileEmployment = {}));
var ProfileIncome;
(function (ProfileIncome) {
    ProfileIncome["LESS_THAN_25000"] = "less-than-25000";
    ProfileIncome["BETWEEN_25000_34999"] = "25000-34999";
    ProfileIncome["BETWEEN_35000_49999"] = "35000-49999";
    ProfileIncome["BETWEEN_50000_74999"] = "50000-74999";
    ProfileIncome["BETWEEN_75000_99999"] = "75000-99999";
    ProfileIncome["BETWEEN_100000_149999"] = "100000_149999";
    ProfileIncome["MORE_THAN_150000"] = "more-than-150000";
    ProfileIncome["NOT_SPECIFIED"] = "not-specified";
})(ProfileIncome = exports.ProfileIncome || (exports.ProfileIncome = {}));
var CashOutProvider;
(function (CashOutProvider) {
    CashOutProvider["VIRTUAL_CARD"] = "VIRTUAL_CARD";
    CashOutProvider["PHYSICAL_CARD"] = "PHYSICAL_CARD";
    CashOutProvider["AMAZON_USD"] = "AMAZON_USD";
    CashOutProvider["PAYPAL_USD"] = "PAYPAL_USD";
    CashOutProvider["BTC"] = "BTC";
    CashOutProvider["PAYPAL_CAD"] = "PAYPAL_CAD";
    CashOutProvider["AMAZON_CAD"] = "AMAZON_CAD";
    CashOutProvider["VIRTUAL_CARD_CAD"] = "VIRTUAL_CARD_CAD";
})(CashOutProvider = exports.CashOutProvider || (exports.CashOutProvider = {}));
var CashOutPaymentStatus;
(function (CashOutPaymentStatus) {
    CashOutPaymentStatus["PENDING"] = "PENDING";
    CashOutPaymentStatus["SUCCESS"] = "SUCCESS";
    CashOutPaymentStatus["FAIL"] = "FAIL";
})(CashOutPaymentStatus = exports.CashOutPaymentStatus || (exports.CashOutPaymentStatus = {}));
var AgeVerificationStatus;
(function (AgeVerificationStatus) {
    AgeVerificationStatus["REQUIRES_ID_VERIFICATION"] = "REQUIRES_ID_VERIFICATION";
    AgeVerificationStatus["REQUIRES_PARENT_CONSENT"] = "REQUIRES_PARENT_CONSENT";
    AgeVerificationStatus["LOCKED"] = "LOCKED";
    AgeVerificationStatus["PENDING"] = "PENDING";
    AgeVerificationStatus["ACCEPTED"] = "ACCEPTED";
    AgeVerificationStatus["REJECTED"] = "REJECTED";
})(AgeVerificationStatus = exports.AgeVerificationStatus || (exports.AgeVerificationStatus = {}));
var AgeLimit;
(function (AgeLimit) {
    AgeLimit[AgeLimit["CHILD"] = 13] = "CHILD";
    AgeLimit[AgeLimit["ADULT"] = 18] = "ADULT";
    AgeLimit[AgeLimit["INTERNATIONAL"] = 18] = "INTERNATIONAL";
})(AgeLimit = exports.AgeLimit || (exports.AgeLimit = {}));
var SchoolType;
(function (SchoolType) {
    SchoolType["PUBLIC"] = "PUBLIC";
    SchoolType["COLLEGES_AND_UNIVERSITIES"] = "COLLEGES_AND_UNIVERSITIES";
    SchoolType["PRIVATE"] = "PRIVATE";
    SchoolType["COLLEGES_AND_UNIVERSITY_CAMPUSES"] = "COLLEGES_AND_UNIVERSITY_CAMPUSES";
})(SchoolType = exports.SchoolType || (exports.SchoolType = {}));
var AssetType;
(function (AssetType) {
    AssetType["ANSWER"] = "ANSWER";
    AssetType["USER"] = "USER";
    AssetType["AGE_VERIFICATION"] = "AGE_VERIFICATION";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
var OrganizationAssetType;
(function (OrganizationAssetType) {
    OrganizationAssetType["CAMPAIGN"] = "CAMPAIGN";
    OrganizationAssetType["POST"] = "POST";
})(OrganizationAssetType = exports.OrganizationAssetType || (exports.OrganizationAssetType = {}));
var OrganizationStatus;
(function (OrganizationStatus) {
    OrganizationStatus["ACTIVE"] = "ACTIVE";
    OrganizationStatus["INACTIVE"] = "INACTIVE";
})(OrganizationStatus = exports.OrganizationStatus || (exports.OrganizationStatus = {}));
var AuthStep;
(function (AuthStep) {
    AuthStep["SIGN_IN"] = "SIGN_IN";
    AuthStep["SIGN_IN_LANDING"] = "SIGN_IN_LANDING";
    AuthStep["SIGN_IN_CHECK_EMAIL"] = "SIGN_IN_CHECK_EMAIL";
})(AuthStep = exports.AuthStep || (exports.AuthStep = {}));
var LedgerEntryCause;
(function (LedgerEntryCause) {
    LedgerEntryCause["INVITED_USER"] = "INVITED_USER";
    LedgerEntryCause["ASSIGNMENT_COMPLETED"] = "ASSIGNMENT_COMPLETED";
    LedgerEntryCause["MANUAL_ADJUSTMENT"] = "MANUAL_ADJUSTMENT";
    LedgerEntryCause["CASHOUT_PROCESSED"] = "CASHOUT_PROCESSED";
})(LedgerEntryCause = exports.LedgerEntryCause || (exports.LedgerEntryCause = {}));
var JobType;
(function (JobType) {
    JobType["DATASET_EXPORT_SCHEDULE"] = "DATASET_EXPORT_SCHEDULE";
    JobType["DISPATCH_ASSIGNMENT_SCHEDULES"] = "DISPATCH_ASSIGNMENT_SCHEDULES";
    JobType["ASSIGNMENT_SCHEDULE"] = "ASSIGNMENT_SCHEDULE";
    JobType["RECOMMEND_PUBLIC_COMMUNITIES"] = "RECOMMEND_PUBLIC_COMMUNITIES";
    JobType["SEND_EMAIL"] = "SEND_EMAIL";
    JobType["ASSIGNMENT_EXPIRATION_SCHEDULE"] = "ASSIGNMENT_EXPIRATION_SCHEDULE";
    JobType["SEND_PUSH_NOTIFICATIONS"] = "SEND_PUSH_NOTIFICATIONS";
    JobType["RESET_DAILY_QUESTION_STREAK_SCHEDULE"] = "RESET_DAILY_QUESTION_STREAK_SCHEDULE";
    JobType["SEND_SMS_MESSAGE"] = "SEND_SMS_MESSAGE";
    JobType["DELETE_USER_SCHEDULE"] = "DELETE_USER_SCHEDULE";
    JobType["POST_PUBLISH_SCHEDULE"] = "POST_PUBLISH_SCHEDULE";
})(JobType = exports.JobType || (exports.JobType = {}));
var AssignmentScheduleStatus;
(function (AssignmentScheduleStatus) {
    AssignmentScheduleStatus["SCHEDULED"] = "SCHEDULED";
    AssignmentScheduleStatus["ASSIGNING"] = "ASSIGNING";
    AssignmentScheduleStatus["SUCCEEDED"] = "SUCCEEDED";
    AssignmentScheduleStatus["FAILED"] = "FAILED";
    AssignmentScheduleStatus["CANCELLED"] = "CANCELLED";
})(AssignmentScheduleStatus = exports.AssignmentScheduleStatus || (exports.AssignmentScheduleStatus = {}));
var EmailType;
(function (EmailType) {
    EmailType["PARENT_CONSENT"] = "PARENT_CONSENT";
    EmailType["ORGANIZATION_INVITE"] = "ORGANIZATION_INVITE";
})(EmailType = exports.EmailType || (exports.EmailType = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["ASSIGNMENT"] = "ASSIGNMENT";
    NotificationType["INVITE"] = "INVITE";
    NotificationType["RECOMMEND_COMMUNITY"] = "RECOMMEND_COMMUNITY";
    NotificationType["ASSIGNMENT_APPROVAL"] = "ASSIGNMENT_APPROVAL";
    NotificationType["CASHOUT_APPROVAL"] = "CASHOUT_APPROVAL";
    NotificationType["CASHOUT_REJECTION"] = "CASHOUT_REJECTION";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
var CouponDuration;
(function (CouponDuration) {
    CouponDuration["FOREVER"] = "forever";
    CouponDuration["ONCE"] = "once";
    CouponDuration["REPEATING"] = "repeating";
})(CouponDuration = exports.CouponDuration || (exports.CouponDuration = {}));
var AuthProvider;
(function (AuthProvider) {
    AuthProvider["PHONE"] = "PHONE";
    AuthProvider["GOOGLE"] = "GOOGLE";
    AuthProvider["MAGIC_LINK"] = "MAGIC_LINK";
})(AuthProvider = exports.AuthProvider || (exports.AuthProvider = {}));
var BranchListeners;
(function (BranchListeners) {
    BranchListeners["INVITE_CODE"] = "inviteCode";
    BranchListeners["SURVEY_SHARE"] = "shareSurvey";
    BranchListeners["COMMUNITY_SHARE"] = "shareCommunity";
})(BranchListeners = exports.BranchListeners || (exports.BranchListeners = {}));
var ProfileFieldComparator;
(function (ProfileFieldComparator) {
    ProfileFieldComparator["IN"] = "IN";
    ProfileFieldComparator["NOT_IN"] = "NOT_IN";
    ProfileFieldComparator["GREATER_THAN"] = "GREATER_THAN";
    ProfileFieldComparator["LESS_THAN"] = "LESS_THAN";
    ProfileFieldComparator["LESS_THAN_OR_EQUAL"] = "LESS_THAN_OR_EQUAL";
    ProfileFieldComparator["GREATER_THAN_OR_EQUAL"] = "GREATER_THAN_OR_EQUAL";
    ProfileFieldComparator["EQUAL"] = "EQUAL";
    ProfileFieldComparator["TRUE"] = "TRUE";
    ProfileFieldComparator["FALSE"] = "FALSE";
})(ProfileFieldComparator = exports.ProfileFieldComparator || (exports.ProfileFieldComparator = {}));
var CompletionActionType;
(function (CompletionActionType) {
    CompletionActionType["ASSIGN_SURVEY"] = "ASSIGN_SURVEY";
})(CompletionActionType = exports.CompletionActionType || (exports.CompletionActionType = {}));
var ActionType;
(function (ActionType) {
    ActionType["SHARE_COMMUNITY"] = "SHARE_COMMUNITY";
    ActionType["INVITE_USER"] = "INVITE_USER";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var PipedOptionCondition;
(function (PipedOptionCondition) {
    PipedOptionCondition["SELECTED"] = "SELECTED";
    PipedOptionCondition["UNSELECTED"] = "UNSELECTED";
})(PipedOptionCondition = exports.PipedOptionCondition || (exports.PipedOptionCondition = {}));
var SMSMessageStatus;
(function (SMSMessageStatus) {
    SMSMessageStatus["SUCCESS"] = "SUCCESS";
    SMSMessageStatus["FAILED"] = "FAILED";
})(SMSMessageStatus = exports.SMSMessageStatus || (exports.SMSMessageStatus = {}));
var DeleteUserScheduleStatus;
(function (DeleteUserScheduleStatus) {
    DeleteUserScheduleStatus["QUEUED"] = "QUEUED";
    DeleteUserScheduleStatus["FINISHED"] = "FINISHED";
})(DeleteUserScheduleStatus = exports.DeleteUserScheduleStatus || (exports.DeleteUserScheduleStatus = {}));
var CommunityProfileLinkType;
(function (CommunityProfileLinkType) {
    CommunityProfileLinkType["INSTAGRAM"] = "INSTAGRAM";
    CommunityProfileLinkType["TWITTER"] = "TWITTER";
    CommunityProfileLinkType["YOUTUBE"] = "YOUTUBE";
    CommunityProfileLinkType["TIKTOK"] = "TIKTOK";
    CommunityProfileLinkType["SPOTIFY"] = "SPOTIFY";
    CommunityProfileLinkType["CUSTOM"] = "CUSTOM";
})(CommunityProfileLinkType = exports.CommunityProfileLinkType || (exports.CommunityProfileLinkType = {}));
var CommunityProfileTheme;
(function (CommunityProfileTheme) {
    CommunityProfileTheme["DEFAULT"] = "DEFAULT";
})(CommunityProfileTheme = exports.CommunityProfileTheme || (exports.CommunityProfileTheme = {}));
var PostStatus;
(function (PostStatus) {
    PostStatus["UNPUBLISHED"] = "UNPUBLISHED";
    PostStatus["PUBLISHED"] = "PUBLISHED";
    PostStatus["ARCHIVED"] = "ARCHIVED";
})(PostStatus = exports.PostStatus || (exports.PostStatus = {}));
var PostAssetType;
(function (PostAssetType) {
    PostAssetType["IMAGE"] = "IMAGE";
    PostAssetType["VIDEO"] = "VIDEO";
    PostAssetType["AUDIO"] = "AUDIO";
})(PostAssetType = exports.PostAssetType || (exports.PostAssetType = {}));
