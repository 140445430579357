import * as React from 'react'
import styled from 'styled-components'
import qs from 'qs'

interface Props {
  src: string
  params?: object
}

const RetoolEmbedIframe = styled.iframe`
  display: flex;
  height: 100%;
  width: 100%;
  border: 0;
`

const RetoolEmbed: React.FC<Props> = ({ src, params }: Props) => {
  const hash = params ? qs.stringify(params) : ''
  return <RetoolEmbedIframe src={`${src}#${hash}`} />
}

export default RetoolEmbed
