import styled from 'styled-components'
import { gradients, colors, mediaQuery } from '../../../styles'
import { navBarAnimationDuration } from '../styled'
import Box from '../../Box'

export const publicBarWidth = '50%'

export const authenticatedBarWidth = '260px'

const wrapperPadding = 50

export const Wrapper = styled.aside<{ width: string }>`
  width: ${({ width }) => width};
  transition: width ${navBarAnimationDuration}s;
  height: 100vh;

  padding: ${({ width }) => (width !== '0' ? wrapperPadding : 0)}px;
  position: absolute;
  left: 0;

  background: ${gradients.purple};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  overflow: auto;

  ${mediaQuery.smallMax} {
    display: none;
  }
`

export const Nav = styled.nav.attrs({
  role: 'navigation',
})`
  margin-top: 20px;
`

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const MenuItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & > a {
    font-size: 1rem;
    line-height: 130%;
    opacity: 0.7;
    text-decoration: none;
    color: ${colors.white};
    transition: opacity 0.2s ease-in-out;
  }

  & > a.active {
    opacity: 1;
  }
`

export const MenuSubitem = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & > a {
    font-size: 1rem;
    line-height: 130%;
    opacity: 0.7;
    padding-left: 1rem;
    text-decoration: none;
    color: ${colors.white};
    transition: opacity 0.2s ease-in-out;
  }

  & > a.active {
    opacity: 1;
  }
`

export const Divider = styled.div`
  background: rgba(239, 239, 239, 0.2);
  height: 1px;
  width: calc(100% + 100px);
  margin-bottom: 36px;
  margin-left: -50px;
`

export const LogoContainer = styled(Box)<{ isCenter: boolean }>`
  position: relative;
  left: ${({ isCenter }) => (isCenter ? '50%' : '0')};
  margin-left: ${({ isCenter }) => (isCenter ? `-${wrapperPadding}px` : 0)};
  transition: all ${navBarAnimationDuration}s;
`
