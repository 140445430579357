import * as React from 'react'
import { ListOrganizationCommunityPermissionsQuery } from '@zebra-iq/common'
import { NavLink } from 'react-router-dom'
import { useContainer } from 'use-container'
import { useQuery } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { useHistory } from 'react-router'
import Logo from '../../Svg/Logo'
import Box from '../../Box'
import { openIntercom } from '../../../lib/intercom'
import { AuthStateContainer } from '../../../data/auth'
import MenuLinks from '../../Menu'
import {
  Wrapper,
  Nav,
  Menu,
  MenuItem,
  MenuSubitem,
  Divider,
  LogoContainer,
} from './styled'

/* eslint-disable jsx-a11y/anchor-is-valid */
interface Props {
  isAuthenticated: boolean
  width: string
}

const AuthenticatedBar: React.FC<Props> = ({
  isAuthenticated,
  width,
}): React.ReactElement | null => {
  const authData = useContainer(AuthStateContainer)
  const { user } = authData.state
  const orgCreated = user?.organizationMemberships?.length > 0 || false

  const history = useHistory()

  const handleSignout = React.useCallback(
    (e: any) => {
      e.preventDefault()
      authData.reset()
      history.replace('/auth')
    },
    [authData, history],
  )

  const { data: ocpData } = useQuery(
    ListOrganizationCommunityPermissionsQuery as DocumentNode,
    {
      skip: !orgCreated,
    },
  )

  const termsOfUse = (
    <a
      href="https://zebraiq.com/terms/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Use
    </a>
  )

  let upperNavLinks: React.ReactElement | null = null
  let footerLinks: React.ReactElement | null = null

  const communities: any[] = ocpData
    ? ocpData.listOrganizationCommunityPermissions.map(
        (ocp: any) => ocp.community,
      )
    : []

  if (!isAuthenticated) {
    footerLinks = <MenuLinks />
  } else if (!user) {
    upperNavLinks = <Menu />
    footerLinks = <MenuItem>{termsOfUse}</MenuItem>
    // return (
    //   <Wrapper width={width}>
    //     <LogoContainer>
    //       <Logo width={100} />
    //       <Nav>
    //         <Menu />
    //       </Nav>
    //     </LogoContainer>
    //     <Box width={1}>
    //       <Divider />
    //       <Menu>
    //         <MenuItem>{termsOfUse}</MenuItem>
    //       </Menu>
    //     </Box>
    //   </Wrapper>
    // )
  } else if (!user.acceptedTerms) {
    upperNavLinks = <Menu />
    footerLinks = (
      <>
        <MenuItem>{termsOfUse}</MenuItem>
        <MenuItem>
          <a href="/auth" onClick={handleSignout}>
            Sign Out
          </a>
        </MenuItem>
      </>
    )
    // return (
    //   <Wrapper width={width}>
    //     <LogoContainer>
    //       <Logo width={100} />
    //       <Nav>
    //         <Menu />
    //       </Nav>
    //     </LogoContainer>
    //     <Box width={1}>
    //       <Divider />
    //       <Menu>
    //         <MenuItem>{termsOfUse}</MenuItem>
    //         <MenuItem>
    //           <a href="/auth" onClick={handleSignout}>
    //             Sign Out
    //           </a>
    //         </MenuItem>
    //       </Menu>
    //     </Box>
    //   </Wrapper>
    // )
  } else {
    upperNavLinks = (
      <>
        <MenuItem>
          <NavLink exact to="/">
            Overview
          </NavLink>
        </MenuItem>
        {communities.map(({ id, name }) => {
          const basePath = `/communities/${id}`

          return (
            <React.Fragment key={id}>
              <MenuItem>
                <NavLink to={`${basePath}/campaigns`}>
                  {`${name} Community`}
                </NavLink>
              </MenuItem>
              <MenuSubitem>
                <NavLink to={`${basePath}/campaigns`}>Campaigns</NavLink>
              </MenuSubitem>
              <MenuSubitem>
                <NavLink to={`${basePath}/responses`}>Responses</NavLink>
              </MenuSubitem>
              <MenuSubitem>
                <NavLink to={`${basePath}/audience`}>Audience</NavLink>
              </MenuSubitem>
            </React.Fragment>
          )
        })}
        {/* <MenuItem>
              <NavLink to="/trends/slang">Trends</NavLink>
            </MenuItem>
            <MenuSubitem>
              <NavLink to="/trends/slang">Gen Z Slang</NavLink>
            </MenuSubitem>*/}
      </>
    )
    footerLinks = (
      <>
        <MenuItem>
          <NavLink to="/settings">Settings</NavLink>
        </MenuItem>
        <MenuItem>
          <a href="#" onClick={() => openIntercom()}>
            Support
          </a>
        </MenuItem>
        <MenuItem>{termsOfUse}</MenuItem>
      </>
    )
  }

  return (
    <Wrapper width={width}>
      <LogoContainer isCenter={!isAuthenticated}>
        <Logo width={100} />
        <Nav>
          <Menu>{upperNavLinks}</Menu>
        </Nav>
      </LogoContainer>
      <Box width={1}>
        <Divider />
        <Menu>{footerLinks}</Menu>
      </Box>
    </Wrapper>
  )

  // return (
  //   <Wrapper width={width}>
  //     <LogoContainer>
  //       <Logo width={100} />
  //       <Nav>
  //         <Menu>
  //           <MenuItem>
  //             <NavLink exact to="/">
  //               Overview
  //             </NavLink>
  //           </MenuItem>
  //           {communities.map(({ id, name }) => {
  //             const basePath = `/communities/${id}`

  //             return (
  //               <React.Fragment key={id}>
  //                 <MenuItem>
  //                   <NavLink to={`${basePath}/campaigns`}>
  //                     {`${name} Community`}
  //                   </NavLink>
  //                 </MenuItem>
  //                 <MenuSubitem>
  //                   <NavLink to={`${basePath}/campaigns`}>Campaigns</NavLink>
  //                 </MenuSubitem>
  //                 <MenuSubitem>
  //                   <NavLink to={`${basePath}/responses`}>Responses</NavLink>
  //                 </MenuSubitem>
  //                 <MenuSubitem>
  //                   <NavLink to={`${basePath}/audience`}>Audience</NavLink>
  //                 </MenuSubitem>
  //               </React.Fragment>
  //             )
  //           })}
  //           {/* <MenuItem>
  //             <NavLink to="/trends/slang">Trends</NavLink>
  //           </MenuItem>
  //           <MenuSubitem>
  //             <NavLink to="/trends/slang">Gen Z Slang</NavLink>
  //           </MenuSubitem>*/}
  //         </Menu>
  //       </Nav>
  //     </LogoContainer>
  //     <Box width={1}>
  //       <Divider />
  //       <Menu>
  //         <MenuItem>
  //           <NavLink to="/settings">Settings</NavLink>
  //         </MenuItem>
  //         <MenuItem>
  //           <a href="#" onClick={() => openIntercom()}>
  //             Support
  //           </a>
  //         </MenuItem>
  //         <MenuItem>{termsOfUse}</MenuItem>
  //       </Menu>
  //     </Box>
  //   </Wrapper>
  // )
}

export default AuthenticatedBar
