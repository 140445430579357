import React from 'react'
import { useContainer } from 'use-container'
import styled from 'styled-components'
import { SignInStateContainer } from '../../components/SignIn/SignInStateContainer'
import useSignInGoogle from '../../components/SignIn/useSignInGoogle'
import useSignInEmailLink from '../../components/SignIn/useSignInEmailLink'
import SignInGoogleButton from '../../components/SignIn/SignInGoogleButton'
import SignInEmailLinkForm from '../../components/SignIn/SignInEmailLinkForm'
import { colors, mediaQuery } from '../../styles'
import Box from '../../components/Box'
import LoadingSpinner from '../../components/Svg/LoadingSpinner'
import ErrorBubble from '../../components/ErrorBubble'
import { HeaderLarge, HeaderDescription } from './styled'
import { AuthProps } from './props'

const MAX_WIDTH = '500px'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  max-width: ${MAX_WIDTH};
  width: 100%;
`

const WrapperColumnRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  ${mediaQuery.tablet} {
    flex-direction: row;
  }
`

const ColumnFlex = styled.div`
  flex: 1;
  width: 100%;
`

const TextParagraph = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${colors.white};
  padding: 0px 17px;
`

const Relative = styled.div`
  position: relative;
  width: 100%;
`

const VisibleContainer = styled.div<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.2s;
`

const AuthSignIn: React.FC<AuthProps> = ({ onAuth }) => {
  const signInData = useContainer(SignInStateContainer)

  const [lastEmail, changeEmail, startEmailLinkSignIn] = useSignInEmailLink(
    onAuth,
  )
  const startGoogleSignIn = useSignInGoogle(onAuth)

  return (
    <Wrapper>
      <HeaderLarge>Get on the waitlist.</HeaderLarge>
      <HeaderDescription>
        Zebra is currently rolling out to creators in stages. Claim your spot
        and we will email you when your profile is ready.
      </HeaderDescription>

      <Relative>
        {signInData.state.loading ? (
          <Box
            py="10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            position="absolute"
          >
            <LoadingSpinner />
          </Box>
        ) : null}

        <VisibleContainer visible={!signInData.state.loading}>
          <ErrorBubble
            isVisible={signInData.state.error}
            label={signInData.state.errorMessage}
          />

          <WrapperColumnRow>
            <ColumnFlex>
              <SignInEmailLinkForm
                onChangeEmail={changeEmail}
                onSubmit={startEmailLinkSignIn}
                value={signInData.state.email || lastEmail}
              />
            </ColumnFlex>

            <TextParagraph>Or</TextParagraph>

            <ColumnFlex>
              <SignInGoogleButton onClick={startGoogleSignIn} />
            </ColumnFlex>
          </WrapperColumnRow>
        </VisibleContainer>
      </Relative>
    </Wrapper>
  )
}

export default AuthSignIn
