import React from 'react'
import styled from 'styled-components'
import { useContainer } from 'use-container'
import Box from '../../../../components/Box'
import { OnboardingStepProps, OnboardingKeys } from '../../props'
import { colors, gradients, mediaQuery } from '../../../../styles'
import { InputStyle } from '../styled'
import { HeaderLarge } from '../../../Auth/styled'
import ErrorBubble from '../../../../components/ErrorBubble'
import { SignInStateContainer } from '../../../../components/SignIn/SignInStateContainer'
import OnboardingPhoneInput from './OnboardingPhoneInput'
import OnboardingTextInput from './OnboardingTextInput'
import OnboardingCheckbox from './OnboardingCheckbox'
import {
  onboardingForm,
  onboardingInputFields,
  onboardingCheckboxFields,
} from './constants'

const FormLabel = styled.label`
  padding-left: 6px;
  padding-bottom: 8px;
  font-size: 15px;
`

const FormLabelStatus = styled.span`
  opacity: 0.5;
  padding-left: 5px;
`

const FormWrapper = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
  width: 100%;

  ${mediaQuery.tablet} {
    width: ${({ width }) => width};
  }
`

const OnboardingSubmitInput = styled.input`
  display: none;
`

const OnboardingSubmitButton = styled.button`
  ${InputStyle}

  background: ${colors.white};
  cursor: pointer;
  z-index: 10;
  width: fit-content;
  margin: 0 auto;
  border: 0px;
`

const OnboardingSubmitButtonText = styled.h4<{ isValid: boolean }>`
  ${({ isValid }) => {
    if (isValid) {
      return `
        background: -webkit-${gradients.pinkOrange};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
    }
    return ''
  }}
  color: #cbcbcb;
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  transition: all 0.2s;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 40px;

  ${mediaQuery.tablet} {
    padding-top: 50px;
  }
`

export interface FormInput {
  onChange: (e: any) => void
  label: OnboardingKeys
  value: any
  minLength?: number
  maxLength?: number
  required: boolean
}

const OnboardingForm: React.SFC<OnboardingStepProps> = ({
  data,
  onChange,
  onNext,
  isLoading,
}): React.ReactElement => {
  const signInCtx = useContainer(SignInStateContainer)

  const isValid = React.useCallback((): {
    isFormValid: boolean
    errorMessage: string
  } => {
    const validations = {
      isFormValid: true,
      errorMessage: '',
    }

    Object.keys(data).forEach((key: string) => {
      const dataKey = key as OnboardingKeys
      const value = data[dataKey]

      const onboardingFields = onboardingInputFields as { [key: string]: any }
      const checkboxFields = onboardingCheckboxFields as { [key: string]: any }

      // if input field
      if (onboardingFields[key] && onboardingFields[key].required && !value) {
        validations.isFormValid = false
        validations.errorMessage = onboardingFields[key].errorMessage
        return
      }

      // if checkbox
      if (checkboxFields[key] && checkboxFields[key].required && !value) {
        validations.isFormValid = false
        validations.errorMessage = checkboxFields[key].errorMessage
      }
    })

    return validations
  }, [data])

  const { isFormValid } = isValid()

  const handleOnSubmit = React.useCallback(
    (e: any): void => {
      e.preventDefault()

      const { isFormValid: isFormSubmissionValid, errorMessage } = isValid()

      if (errorMessage) {
        signInCtx.setError(errorMessage)
      }

      if (isLoading || !isFormSubmissionValid) return

      onNext()
    },
    [onNext, signInCtx, isValid, isLoading],
  )

  const handleOnChange = (value: any): void => {
    onChange(value)
    signInCtx.clearError()
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <HeaderLarge>{onboardingForm.title}</HeaderLarge>

      <Box display="flex" flexDirection="column" width={['100%', '550px']}>
        <Form onSubmit={handleOnSubmit}>
          {onboardingForm.inputFields.map(
            ({ label, title, input, required, ...rest }, indx) => {
              const isEven = indx % 2 === 0

              const props = {
                label,
                value: data[label],
                onChange: handleOnChange,
                required,
              }

              return (
                <FormWrapper key={label} width={isEven ? '55%' : '41%'}>
                  <FormLabel htmlFor={label}>
                    {title}
                    <FormLabelStatus>
                      {required ? 'Required' : 'Optional'}
                    </FormLabelStatus>
                  </FormLabel>
                  {input === 'phone' ? (
                    <OnboardingPhoneInput {...props} {...rest} />
                  ) : (
                    <OnboardingTextInput {...props} {...rest} />
                  )}
                </FormWrapper>
              )
            },
          )}

          {onboardingForm.checkboxFields.map(({ label, required, title }) => (
            <OnboardingCheckbox
              key={label}
              label={label}
              value={data[label]}
              onChange={handleOnChange}
              required={required}
            >
              {title}
            </OnboardingCheckbox>
          ))}

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            position="relative"
          >
            <ErrorBubble
              isVisible={signInCtx.state.error}
              label={signInCtx.state.errorMessage}
            />

            <OnboardingSubmitInput type="submit" value="Submit Waitlist" />

            <OnboardingSubmitButton type="submit" onClick={handleOnSubmit}>
              <OnboardingSubmitButtonText isValid={isFormValid}>
                Submit Waitlist
              </OnboardingSubmitButtonText>
            </OnboardingSubmitButton>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default OnboardingForm
