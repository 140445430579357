import { GetOrganizationApplicationQuery } from '@zebra-iq/common'
import { DocumentNode } from 'graphql'
import { useHistory } from 'react-router'
import { useContainer } from 'use-container'
import { useLazyQuery } from '@apollo/react-hooks'
import { ApolloError } from 'apollo-client'
import { AuthStateContainer } from '../data/auth'
import { OrganizationApplicationStateContainer } from '../data/organizationApplication'

interface OrganizationApplication {
  id: string
  referralURL: string
  placeInLine: number
  referralCount: number
}

interface Props {
  onCompleted?: (data: OrganizationApplication) => void
  onError?: (err?: ApolloError) => void
}

const useGetOrganizationApplication = ({ onCompleted, onError }: Props = {}): [
  () => void,
  {
    loading: boolean
    data: OrganizationApplication
    error?: ApolloError
  },
] => {
  const authCtx = useContainer(AuthStateContainer)
  const orgAppCtx = useContainer(OrganizationApplicationStateContainer)
  const history = useHistory()

  const handleOnClear = (): void => {
    authCtx.reset()
    history.push('/')
  }

  const [getOrganization, { data, loading, error }] = useLazyQuery(
    GetOrganizationApplicationQuery as DocumentNode,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          userID: null,
        },
      },
      onCompleted(data) {
        if (!data) {
          handleOnClear()
        }

        const orgData = data && data.getOrganizationApplication

        orgAppCtx.setOrgApplication(orgData)
        orgAppCtx.persist()

        if (onCompleted) {
          onCompleted(orgData)
        }
      },
      onError(error) {
        if (onError) {
          onError(error)
        }
      },
    },
  )

  return [
    getOrganization,
    { loading, data: data && data.getOrganizationApplication, error },
  ]
}

export default useGetOrganizationApplication
