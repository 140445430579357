import React from 'react'
import { HeaderLarge, HeaderDescription } from './styled'
import { AuthProps } from './props'

const AuthEmailConfirm: React.FC<AuthProps> = (): React.ReactElement => {
  return (
    <>
      <HeaderLarge>Check your email!</HeaderLarge>
      <HeaderDescription>
        We just sent you a verification link. Confirm it, and come back here!
      </HeaderDescription>
    </>
  )
}

export default AuthEmailConfirm
