import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Main from '../components/Main'
import Box from '../components/Box'
import Navigation from '../components/Navigation'
import useJam from '../lib/jam'
import { getPrivateRoutes, getPublicRoutes } from '../routes/utils'

const App: React.FC = (): React.ReactElement => {
  useJam()

  return (
    <Router>
      <Box display="flex">
        <Navigation>
          <Main>
            <Switch>
              {getPrivateRoutes()}
              {getPublicRoutes()}

              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Main>
        </Navigation>
      </Box>
    </Router>
  )
}

export default App
