import styled from 'styled-components'
import { mediaQuery } from '../../styles'

export const OnboardingWelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.medium} {
    flex-direction: row;
  }
`

export const OnboardingHeader = styled.h1`
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  margin: 0;
  padding-bottom: 10px;
`

export const OnboardingDescription = styled.h4`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 300;
  margin: 0;

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const OnboardingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  overflow-x: hidden;
  overflow-y: auto;

  ${mediaQuery.medium} {
    overflow: visible;
    max-width: 750px;
  }
`

export const MaxWidthForm = styled.div`
  width: 100%;

  ${mediaQuery.tablet} {
    max-width: 400px;
  }
`
