import * as React from 'react'
import styled, { css } from 'styled-components'
import { gradients, colors } from '../../../styles'

const Input = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
  position: absolute;
`

let id = 0
const getId = () => {
  id += 1
  return id
}

interface Props {
  options: string[]
  onChange: (value: string) => void
  value: string
  renderEntry: (e: React.ReactElement, index: number) => React.ReactElement
}

const Label = styled.div<{ active: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #b6b6b6;

  ${({ active }) =>
    active &&
    css`
      background: ${gradients.purple};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `};
`

const Span = styled.span<{ active: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  position: relative;
  margin-right: 8px;

  border: 1px solid #e0e0e0;

  ${({ active }) =>
    active &&
    css`
      background: ${gradients.purple};
      border: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        background-color: ${colors.white};
      }
    `};
`

interface RadioEntryProps extends React.HTMLProps<HTMLInputElement> {
  label: string
  active: boolean
}

const RadioEntry: React.FC<RadioEntryProps> = ({ label, active, ...rest }) => (
  <Label active={active}>
    <Input {...(rest as any)} />
    <Span active={active} />
    {label}
  </Label>
)

const Radio: React.FC<Props> = ({ options, renderEntry, onChange, value }) => {
  const [radioId] = React.useState(`radio-${getId()}`)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value)

  return (
    <>
      {options.map((option, index) => (
        <label htmlFor={option} key={option}>
          {renderEntry(
            <RadioEntry
              label={option}
              name={radioId}
              value={option}
              onChange={handleChange}
              active={value === option}
            />,
            index,
          )}
        </label>
      ))}
    </>
  )
}

export default Radio
