import styled, { css } from 'styled-components'
import {
  color,
  ColorProps,
  lineHeight,
  LineHeightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  width,
  WidthProps,
  fontWeight,
  FontWeightProps,
} from 'styled-system'
import { colors } from '../styles'

interface TypographyProps
  extends SpaceProps,
    WidthProps,
    TextAlignProps,
    ColorProps,
    LineHeightProps,
    FontWeightProps {
  uppercase?: boolean
  bold?: boolean
  italic?: boolean
  preventClip?: boolean
  noWrap?: boolean
  white?: boolean
}

const BaseTypography = css<TypographyProps>`
  margin: 0;

  ${space};
  ${width};
  ${color};
  ${textAlign};
  ${lineHeight};
  ${fontWeight};

  ${({ bold, italic, uppercase, noWrap, white }) => css`
    ${bold && 'font-weight: bold'};
    ${italic && 'font-style: italic'};
    ${uppercase && 'text-transform: uppercase'};
    ${noWrap && 'white-space: nowrap;'};
    ${white && 'color: white;'};
  `};
`

export const EllipsisOverflow = css<TypographyProps>`
  ${({ preventClip }) =>
    !preventClip &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`

export const Heading2 = styled.h2`
  font-size: 2rem;
  font-weight: bold;

  color: ${colors.header};

  ${BaseTypography}
`

export const Heading3 = styled.h3`
  font-size: 1.75rem;
  font-weight: bold;

  color: ${colors.header};

  ${BaseTypography}
`

export const Heading4 = styled.h4`
  font-size: 1.375rem;
  font-weight: 600;

  color: #858585;

  ${BaseTypography}
`

export const Heading5 = styled.h4`
  font-size: 1rem;
  font-weight: bold;

  color: ${colors.header};

  ${BaseTypography}
`

export const Span = styled.span`
  font-size: 0.875rem;
  color: ${colors.primaryText};

  ${BaseTypography}
`

export const SpacedText = styled.span`
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 0.625rem;
  font-size: 10px;

  color: ${colors.spacedText};

  ${BaseTypography}
`

export const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  color: ${colors.labelText};
  display: block;

  ${BaseTypography}
`

export const Paragraph = styled.p`
  font-size: 0.875rem;
  color: ${colors.primaryText};

  ${BaseTypography}
`

export const SmallText = styled.span`
  font-size: 0.75rem;
  line-height: 14px;
  color: ${colors.labelText};

  ${BaseTypography}
`
