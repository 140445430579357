import { Observable, DocumentNode } from 'apollo-link'
import { ApolloClient } from 'apollo-client'
import { SignInRefreshTokenMutation } from '@zebra-iq/common'
import { getContainer } from 'use-container'
import logger from '../../lib/logger'
import { AuthStateContainer } from '../auth'

export const promiseToObservable = (
  promise: Promise<Response>,
): Observable<Response> =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      err => subscriber.error(err),
    )
  })

export const refreshToken = async (
  client: ApolloClient<any>,
): Promise<string | null> => {
  const authData = getContainer(AuthStateContainer)

  const resetStore = async (): Promise<void> => {
    authData.reset()
    await client.clearStore()
  }

  if (!authData.state.refreshToken) {
    resetStore()
    return null
  }

  try {
    const { data } = await client.mutate({
      mutation: SignInRefreshTokenMutation as DocumentNode,
      variables: {
        refreshToken: authData.state.refreshToken,
      },
    })

    const signInResult: any = data.signInRefreshToken

    if (signInResult) {
      authData.setRefreshToken(signInResult.refreshToken)
      authData.setAccessToken(signInResult.accessToken)
      authData.setUser(signInResult.user)

      authData.persist()

      return data.signInRefreshToken
    }
    resetStore()
  } catch (err) {
    logger.error(err)
    resetStore()
  }

  return null
}
