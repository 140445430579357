import React, { useCallback } from 'react'
import styled from 'styled-components'
import { mediaQuery, colors } from '../../styles'
import ArrowRight from '../Svg/ArrowRight'
import { formValidations } from '../../lib/form'

const Form = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  ${mediaQuery.medium} {
    align-items: center;
    flex-direction: row;
  }
`

const TextInput = styled.input`
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  border: 0px;
  color: ${colors.white};
  padding: 13px 35px 13px 15px;
  font-weight: 600;
  font-size: 15px;
  min-height: 49px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.45);
  }
`

const ButtonInputArrow = styled.input`
  display: none;
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

interface Props {
  value: string | null
  onChangeEmail: Function
  onSubmit: Function
}

const SignInEmailLinkForm = ({
  value,
  onChangeEmail,
  onSubmit,
}: Props): React.ReactElement => {
  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeEmail(event.target.value)
    },
    [onChangeEmail],
  )

  const handleSubmit = useCallback(
    (event): void => {
      event.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <TextInput
        {...formValidations.email}
        required
        id="email"
        placeholder="you@workemail.com"
        value={value || ''}
        onChange={handleEmailChange}
      />
      <ButtonInputArrow type="submit" value="Submit" />
      <ButtonWrapper onClick={handleSubmit}>
        <ArrowRight />
      </ButtonWrapper>
    </Form>
  )
}

export default SignInEmailLinkForm
