import { DEFAULT_COUNTRY } from './constants'

export const formValidations = {
  name: {
    minLength: 2,
    maxLength: 255,
    autoComplete: 'name',
  },
  industry: {
    minLength: 2,
    maxLength: 500,
  },
  agencyLabel: {
    minLength: 2,
    maxLength: 500,
  },
  phoneNumber: {
    placeholder: `${DEFAULT_COUNTRY.COUNTRY_CODE_NUMBER_PREFIX} (650) 555-1234`,
    minLength: 2,
    maxLength: 17,
    autoComplete: 'tel-country-code',
  },
  email: {
    minLength: 5,
    maxLength: 255,
    autoComplete: 'email',
    name: 'email',
    type: 'email',
  },
}
