import React from 'react'
import styled from 'styled-components'
import { gradients } from '../styles'

const ButtonWrapper = styled.button`
  background: #fff;
  border: 0;
  border-radius: 12px;
  padding: 12px 36px;
  cursor: pointer;
`

const ButtonText = styled.h4<{ gradient: string }>`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  background: ${({ gradient }) => `-webkit-${gradient}`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

interface Props {
  onClick?: (e?: any) => void
  gradient?: string
  label: string
}

const ButtonGradientText: React.SFC<Props> = ({
  label,
  gradient = gradients.pinkOrange,
  onClick = () => {},
}): React.ReactElement => {
  return (
    <ButtonWrapper onClick={onClick}>
      <ButtonText gradient={gradient}>{label}</ButtonText>
    </ButtonWrapper>
  )
}

export default ButtonGradientText
