import Overview from '../pages/Overview'
import Auth from '../pages/Auth'
import Onboarding from '../pages/Onboarding'
import TrendsSlang from '../pages/Trends/Slang'
import CommunityCampaigns from '../pages/Communities/Campaigns'
import CommunityResponses from '../pages/Communities/Responses'
import CommunityAudience from '../pages/Communities/Audience'
import Settings from '../pages/Settings'
import Waitlist from '../pages/Waitlist'
import { getRoutesStringList } from './utils'

export interface RouteType {
  route: string
  redirectsFrom?: string[]
  component: any
}

export const privateRoutes: { [key: string]: RouteType } = {
  homepage: {
    route: '/',
    component: Overview,
  },
  onboarding: {
    route: '/onboarding',
    component: Onboarding,
  },
  trends: {
    route: '/trends/slang',
    component: TrendsSlang,
    redirectsFrom: ['/trends'],
  },
  communityCampaigns: {
    route: '/communities/:id/campaigns',
    component: CommunityCampaigns,
  },
  communityResponse: {
    route: '/communities/:id/responses',
    component: CommunityResponses,
  },
  communityAudience: {
    route: '/communities/:id/audience',
    component: CommunityAudience,
  },
  settings: {
    route: '/settings',
    component: Settings,
  },
}

export const privateRoutesStringList: string[] = getRoutesStringList(
  privateRoutes,
)

export const fullScreenRoutesList = ['/onboarding', '/auth', '/waitlist']

export const publicRoutes: { [key: string]: RouteType } = {
  auth: {
    route: '/auth',
    component: Auth,
  },
  waitlist: {
    route: '/waitlist',
    component: Waitlist,
  },
}

export const publicRoutesStringList = getRoutesStringList(publicRoutes)
