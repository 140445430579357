import { Container } from 'use-container'

interface OrganizationApplication {
  id: string
  placeInLine: number
  referralURL: string
  referralCount: number
}

interface IApplication {
  organizationApplication: OrganizationApplication | null
}

export const ORG_APP_IDENTIFIER = 'org-application'

export class OrganizationApplicationStateContainer extends Container<
  IApplication
> {
  constructor() {
    super()

    const organizationApplication = JSON.parse(
      localStorage.getItem(ORG_APP_IDENTIFIER) as string,
    ) as OrganizationApplication

    this.state = {
      organizationApplication: organizationApplication || null,
    }
  }

  setOrgApplication = (organizationApplication: OrganizationApplication) =>
    this.setState({ organizationApplication })

  removeOrgApplication = () => {
    localStorage.removeItem(ORG_APP_IDENTIFIER)
    this.setState({ organizationApplication: null })
  }

  persist = (): void => {
    localStorage.setItem(
      ORG_APP_IDENTIFIER,
      JSON.stringify(this.state.organizationApplication || ''),
    )
  }

  reset = (): void => {
    localStorage.removeItem(ORG_APP_IDENTIFIER)

    this.setState({
      organizationApplication: null,
    })
  }
}
