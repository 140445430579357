const createQuery = (size: number, minMax: 'min' | 'max' = 'min') =>
  `@media screen and (${minMax}-width: ${size}px)`

const screenSmMax = 831
const screenMdMin = screenSmMax + 1
const screenMdMax = 992
const screenLgMin = screenMdMax + 1
const screenLgMax = 1200
const screenXlMin = screenLgMax + 1
const screenTabletMin = 768

export const SIZES = {
  small: screenSmMax,
  medium: screenMdMin,
  large: screenLgMin,
  xlarge: screenXlMin,
  tablet: screenTabletMin,
}

export const mediaQuery = {
  // Small devices (landscape phones, 576px and up)
  small: createQuery(SIZES.small),
  smallMax: createQuery(SIZES.medium - 1, 'max'),
  // Medium devices (tablets, 768px and up)
  tablet: createQuery(SIZES.tablet),
  medium: createQuery(SIZES.medium),
  mediumMax: createQuery(SIZES.large - 1, 'max'),
  // Large devices (desktops, 992px and up)
  large: createQuery(SIZES.large),
  // Extra large devices (large desktops, 1200px and up)
  xlarge: createQuery(SIZES.xlarge),
}

export const breakpoints = [
  `${screenSmMax}px`,
  `${screenMdMax}px`,
  `${screenLgMax}px`,
]
