import * as React from 'react'
import {
  // AttachPaymentMethodMutation,
  CreateOrganizationApplicationMutation,
  UpdateProfileMutation,
  // SetDefaultPaymentMethodMutation,
  // CreateSubscriptionMutation,
  // UpdateProfileMutation,
} from '@zebra-iq/common'
import { useMutation } from '@apollo/react-hooks'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useHistory } from 'react-router'
import { useContainer } from 'use-container'
import { DocumentNode } from 'graphql'
import Box from '../../components/Box'
import config from '../../config'
import { AuthStateContainer } from '../../data/auth'
import { track, EventType } from '../../lib/events'
import LayoutGradient from '../../layout/LayoutGradient'
import HeaderNavigation from '../../components/HeaderNavigation'
import { ReferralCodeStateContainer } from '../../data/referralCode'
import { trackEvent } from '../../lib/analytics'
import { OrganizationApplicationStateContainer } from '../../data/organizationApplication'
import { SignInStateContainer } from '../../components/SignIn/SignInStateContainer'
import { useAuth } from '../../hooks/useAuth'
import { OnboardingData, OnboardingStepProps, OnboardingKeys } from './props'
import OnboardingNextButton from './components/OnboardingNextButton'
import OnboardingForm from './steps/OnboardingForm'

const ONBOARDING_STEPS: Array<{
  index: number
  Component: React.FC<OnboardingStepProps>
  label: string
}> = [{ index: 0, Component: OnboardingForm, label: 'OnboardingForm' }]

const stripePromise = loadStripe(config.stripe.key)

const Onboarding: React.FC = (): React.ReactElement => {
  const history = useHistory()

  const authData = useContainer(AuthStateContainer)
  const referralCodeCtx = useContainer(ReferralCodeStateContainer)
  const orgCtx = useContainer(OrganizationApplicationStateContainer)
  const signInCtx = useContainer(SignInStateContainer)

  const [currentStep, setCurrentStep] = React.useState(0)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [direction, setDirection] = React.useState<'next' | 'back' | null>(null)
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(true)
  const [data, setData] = React.useState<OnboardingData>({
    [OnboardingKeys.NAME]: '',
    [OnboardingKeys.PHONE_NUMBER]: '',
    [OnboardingKeys.INDUSTRY]: '',
    [OnboardingKeys.AGENCY_LABEL]: '',
    [OnboardingKeys.IS_CREATOR_MANAGER]: false,
    [OnboardingKeys.TERMS_AGREED]: false,
  })

  const orgCreated =
    authData.state.user?.organizationMemberships?.length > 0 || false
  const isWaitlist = orgCreated && authData.state.user?.acceptedTerms

  const { refreshToken } = useAuth()

  React.useEffect(() => {
    track(EventType.OnboardingScreenViewed, {
      screen: ONBOARDING_STEPS[currentStep].label,
    })
  }, [currentStep])

  React.useEffect(() => {
    if (isWaitlist) {
      history.push('/waitlist')
    }
  }, [isWaitlist, history])

  const [createOrganization] = useMutation(
    CreateOrganizationApplicationMutation as DocumentNode,
    {
      onError() {
        authData.reset()
      },
    },
  )
  // const [attachPaymentMethod] = useMutation(AttachPaymentMethodMutation)
  // const [setDefaultPaymentMethod] = useMutation(SetDefaultPaymentMethodMutation)
  // const [createSubscription] = useMutation(CreateSubscriptionMutation)
  const [updateProfile] = useMutation(UpdateProfileMutation as DocumentNode)

  const handleCreateOrganization = React.useCallback(async () => {
    const response = await createOrganization({
      variables: {
        input: {
          fields: {
            [OnboardingKeys.NAME]: data[OnboardingKeys.NAME] || null,
            [OnboardingKeys.PHONE_NUMBER]:
              data[OnboardingKeys.PHONE_NUMBER] || null,
            [OnboardingKeys.INDUSTRY]: data[OnboardingKeys.INDUSTRY] || null,
            [OnboardingKeys.AGENCY_LABEL]:
              data[OnboardingKeys.AGENCY_LABEL] || null,
            [OnboardingKeys.IS_CREATOR_MANAGER]:
              data[OnboardingKeys.IS_CREATOR_MANAGER] || null,
          },
          referralCode: referralCodeCtx.state.referralCode || null,
        },
      },
    })

    if (
      response &&
      response.data &&
      response.data.createOrganizationApplication
    ) {
      track(EventType.CreateOrganization)

      // set application from response
      orgCtx.setOrgApplication(response.data.createOrganizationApplication)
      orgCtx.persist()

      // remove referral code
      referralCodeCtx.reset()

      // remove sign in states
      signInCtx.reset()

      setIsSubmitted(true)

      trackEvent('Creating organization from onboarding')

      await refreshToken()
    } else {
      signInCtx.setError('Error creating organization')
      setIsSubmitted(false)
    }
  }, [
    data,
    createOrganization,
    refreshToken,
    referralCodeCtx,
    signInCtx,
    orgCtx,
  ])

  // const handleAttachPaymentMethod = React.useCallback(async () => {
  //   await attachPaymentMethod({
  //     variables: {
  //       input: {
  //         paymentMethodToken: data.paymentMethodToken,
  //       },
  //     },
  //   })
  //   track(EventType.AttachedPaymentMethod)

  //   await setDefaultPaymentMethod({
  //     variables: {
  //       input: {
  //         paymentMethodToken: data.paymentMethodToken,
  //       },
  //     },
  //   })
  //   track(EventType.SetDefaultPaymentMethod)

  //   await createSubscription({
  //     variables: {
  //       input: {
  //         planID: data.planID,
  //         couponCode: data.couponCode,
  //       },
  //     },
  //   })
  //   track(EventType.CreateSubscription)
  // }, [data, attachPaymentMethod, setDefaultPaymentMethod, createSubscription])
  const handleAcceptTOS = React.useCallback(async () => {
    await updateProfile({
      variables: {
        input: {
          acceptedTerms: true,
        },
      },
    })

    track(EventType.AcceptTerms)
    await refreshToken()
  }, [updateProfile, refreshToken])

  // const handleInvite = React.useCallback(async () => {
  //   history.push('/')
  // }, [history])

  const handleNext = React.useCallback(async () => {
    if (loading) return

    try {
      setLoading(true)

      // only step, so this is the last step
      if (currentStep === ONBOARDING_STEPS.length - 1) {
        if (!orgCreated) {
          await handleCreateOrganization()
        }

        await handleAcceptTOS()
        history.push('/waitlist')
        return
      }
    } finally {
      setLoading(false)
    }

    setDirection('next')
    setCurrentStep(currentStep => {
      const newStep = currentStep + 1

      if (newStep <= ONBOARDING_STEPS.length - 1) {
        return newStep
      }
      return currentStep
    })
  }, [
    history,
    loading,
    orgCreated,
    currentStep,
    setCurrentStep,
    handleCreateOrganization,
    handleAcceptTOS,
    // handleInvite,
  ])

  const handleBack = React.useCallback(() => {
    trackEvent('Pressing back from onboarding')

    const nextStep = currentStep - 1
    const hasUser = isSubmitted

    if (hasUser) {
      // reset submission state
      setIsSubmitted(false)
    }

    // reset data
    if (authData.state.user) {
      authData.reset()
    }

    // clear any sign in errors
    signInCtx.clearError()

    // if organization created
    // if has user, take back all the way to auth
    if (nextStep < 0 || orgCreated || hasUser) {
      // remove all user related data
      history.push('/auth')
    }

    if (currentStep > (orgCreated ? 1 : 0)) {
      setDirection('back')
      setCurrentStep(currentStep => {
        const newStep = currentStep - 1

        if (newStep >= 0) return newStep
        return currentStep
      })
    }
  }, [
    signInCtx,
    currentStep,
    setDirection,
    isSubmitted,
    setCurrentStep,
    orgCreated,
    authData,
    history,
  ])

  const handleChange = React.useCallback(
    (newData: Partial<OnboardingData>) => {
      setData({ ...data, ...newData })
    },
    [data, setData],
  )

  const { Component, label } = ONBOARDING_STEPS[currentStep]

  const isFirstStep = currentStep === 0
  const isLastStep = currentStep === ONBOARDING_STEPS.length - 1

  const { organizationApplication } = orgCtx.state

  React.useEffect(() => {
    if (orgCreated) {
      history.push('/')
    } else if (!orgCreated && organizationApplication) {
      history.push('/waitlist')
    }
  }, [orgCreated, organizationApplication, history])

  if (orgCreated || organizationApplication) return <LayoutGradient />

  return (
    <LayoutGradient>
      <HeaderNavigation onBack={handleBack} footer>
        <Box
          className={
            direction === 'next' ? 'direction-left' : 'direction-right'
          }
        >
          <Elements stripe={stripePromise}>
            <SwitchTransition>
              <CSSTransition
                key={currentStep}
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false)
                }}
                classNames="fade"
              >
                <Component
                  data={data}
                  label={label}
                  onChange={handleChange}
                  isLoading={loading}
                  onBack={handleBack}
                  onNext={handleNext}
                  nextButton={
                    !isLastStep ? (
                      <OnboardingNextButton
                        isFirstStep={isFirstStep}
                        onClick={handleNext}
                      />
                    ) : null
                  }
                />
              </CSSTransition>
            </SwitchTransition>
          </Elements>
        </Box>
      </HeaderNavigation>
    </LayoutGradient>
  )
}

export default Onboarding
