import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  SpaceProps,
  space,
  WidthProps,
  width,
  background,
  color,
} from 'styled-system'
import { Link } from 'react-router-dom'
import { gradients, colors, variables } from '../styles'
import { Span } from './Typography'

interface Styles extends SpaceProps, WidthProps {
  variant?: 'primary' | 'alt' | 'gradientText' | 'inverse' | 'gray'
  background?: string
  color?: string
  type?: 'button' | 'submit'
  href?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  disabled?: boolean
  children?: any
}

const themes = {
  primary: {
    color: colors.white,
    background: gradients.purple,
  },
  gradientText: {
    background: colors.white,
    color: gradients.purple,
  },
  alt: {
    color: colors.disabledText,
    background: colors.disabled,
  },
  inverse: {
    color: gradients.purple,
    background: gradients.purple,
  },
  gray: {
    color: '#6A6A6A',
    background: '#E1E1E1',
  },
}

const WrapperStyle = css<Styles>`
  height: 35px;

  font-weight: 500;
  font-size: 0.875rem;

  border-radius: ${variables.borderRadius};

  cursor: pointer;
  white-space: nowrap;

  border: 0;
  padding: 0 1rem;

  ${space};
  ${width};

  ${({ variant = 'primary' }) => css`
    background: ${themes[variant].background};
    span {
      color: ${themes[variant].color};
    }

    ${variant === 'gradientText' &&
      css`
        background: ${themes[variant].background};

        span {
          background: ${themes[variant].color};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      `};

    ${variant === 'inverse' &&
      css`
        position: relative;
        &::before {
          content: '';
          background: ${colors.white};
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          position: absolute;
          left: 1px;
          top: 1px;
          border-radius: 9px;
        }

        span {
          position: relative;
          background: ${themes[variant].color};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      `};
  `};

  ${background};
  ${color};

  &:disabled {
    color: ${colors.disabledText};
    background: ${colors.disabled};
    cursor: not-allowed;
  }
`

const ButtonWrapper = styled.button<Styles>`
  ${WrapperStyle}
`

const LinkWrapper = styled(Link)<Styles & { to: string }>`
  ${WrapperStyle}
  line-height: 35px;
  text-decoration: none;
`

const Button: React.FC<Styles> = ({ children, href, ...rest }) =>
  href ? (
    <LinkWrapper to={href} {...(rest as Styles)}>
      <Span>{children}</Span>
    </LinkWrapper>
  ) : (
    <ButtonWrapper {...(rest as Styles)}>
      <Span>{children}</Span>
    </ButtonWrapper>
  )

export default Button
