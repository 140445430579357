import React from 'react'
import { colors } from '../../styles'
import { SvgTypes } from './types'

const HelpCircle: React.SFC<SvgTypes> = ({
  size,
  height = 20,
  width = 20,
  fill = colors.white,
}): React.ReactElement => {
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="18.5"
        rx="9.25"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M10.0727 15C10.6541 15 11.0174 14.5804 11.0174 14.0909C11.0174 13.6014 10.6541 13.1818 10.0727 13.1818C9.49128 13.1818 9.12791 13.6014 9.12791 14.0909C9.12791 14.5804 9.49128 15 10.0727 15ZM9.27326 11.3636C9.27326 11.7112 9.55503 11.993 9.90263 11.993H10.0974C10.445 11.993 10.7267 11.7112 10.7267 11.3636C10.7267 9.75525 13.125 9.82517 13.125 7.58741C13.125 5.97902 11.8169 5 10 5C8.60745 5 7.51754 5.67951 7.06647 6.91722C6.91486 7.33323 7.26601 7.72727 7.70879 7.72727C8.06693 7.72727 8.35733 7.45937 8.51992 7.14026C8.78948 6.61119 9.293 6.25874 10 6.25874C11.0174 6.25874 11.5988 6.81818 11.5988 7.58741C11.5988 9.26573 9.27326 8.98601 9.27326 11.3636Z"
        fill={fill}
      />
    </svg>
  )
}

export default HelpCircle
