import * as React from 'react'

interface Props {
  width?: number
}

const Logo: React.FC<Props> = ({ width = 135 }) => (
  <svg
    width={width}
    height={(28 * 100) / width}
    viewBox="0 0 135 28"
    fill="none"
  >
    <path
      d="M0 22.077C0 22.6717 0.480579 23.1539 1.0734 23.1539H12.7588C13.3516 23.1539 13.8322 22.6717 13.8322 22.077V20.677C13.8322 20.0822 13.3516 19.6 12.7588 19.6H5.15821C5.11453 19.6 5.07913 19.5645 5.07913 19.5207C5.07913 19.5035 5.08472 19.4867 5.09504 19.473L13.3528 8.47005C13.493 8.28334 13.5687 8.05597 13.5687 7.82226V6.46158C13.5687 5.86681 13.0882 5.38466 12.4953 5.38466H1.21978C0.626953 5.38466 0.146373 5.86681 0.146373 6.46158V7.86158C0.146373 8.45635 0.626952 8.93851 1.21978 8.93851H8.4365C8.47392 8.93851 8.50425 8.96894 8.50425 9.00648C8.50425 9.02125 8.49946 9.03562 8.49059 9.04741L0.216439 20.0532C0.075979 20.24 0 20.4676 0 20.7017V22.077Z"
      fill="white"
    />
    <path
      d="M31.0872 20.677C31.0872 20.0822 30.6066 19.6 30.0138 19.6H24.4761C23.8832 19.6 23.4027 19.1179 23.4027 18.5231V16.9114C23.4027 16.3166 23.8832 15.8345 24.4761 15.8345H29.5747C30.1675 15.8345 30.6481 15.3523 30.6481 14.7576V13.66C30.6481 13.0652 30.1675 12.5831 29.5747 12.5831H24.4761C23.8832 12.5831 23.4027 12.1009 23.4027 11.5062V10.0154C23.4027 9.42066 23.8832 8.93851 24.4761 8.93851H30.0138C30.6066 8.93851 31.0872 8.45635 31.0872 7.86158V6.46158C31.0872 5.86681 30.6066 5.38466 30.0138 5.38466H20.1873C19.5945 5.38466 19.1139 5.86681 19.1139 6.46158V22.077C19.1139 22.6717 19.5945 23.1539 20.1873 23.1539H30.0138C30.6066 23.1539 31.0872 22.6717 31.0872 22.077V20.677Z"
      fill="white"
    />
    <path
      d="M44.8293 23.1539C48.6057 23.1539 50.9769 21.1426 50.9769 18.0273C50.9769 15.6837 49.1425 13.9222 46.7793 13.7656C46.753 13.7638 46.7321 13.7419 46.7321 13.7154C46.7321 13.6905 46.7506 13.6694 46.7752 13.6657C48.7006 13.376 50.1572 11.7821 50.1572 9.81563C50.1572 7.07841 48.0641 5.38466 44.639 5.38466H37.5741C36.9812 5.38466 36.5007 5.86681 36.5007 6.46158V22.077C36.5007 22.6717 36.9812 23.1539 37.5741 23.1539H44.8293ZM40.7894 9.59199C40.7894 8.99722 41.27 8.51507 41.8628 8.51507H43.4973C45.0488 8.51507 45.971 9.27121 45.971 10.5415C45.971 11.8572 44.961 12.6436 43.2485 12.6436H41.8628C41.27 12.6436 40.7894 12.1614 40.7894 11.5667V9.59199ZM41.8628 20.0235C41.27 20.0235 40.7894 19.5413 40.7894 18.9466V16.4275C40.7894 15.8327 41.27 15.3506 41.8628 15.3506H43.5997C45.5319 15.3506 46.6004 16.1672 46.6004 17.6492C46.6004 19.1917 45.5465 20.0235 43.6437 20.0235H41.8628Z"
      fill="white"
    />
    <path
      d="M60.079 9.78859C60.079 9.19382 60.5596 8.71166 61.1524 8.71166H62.8454C64.5141 8.71166 65.5826 9.75513 65.5826 11.2977C65.5826 12.8704 64.5726 13.8534 62.8747 13.8534H61.1524C60.5595 13.8534 60.079 13.3712 60.079 12.7765V9.78859ZM60.079 17.97C60.079 17.3752 60.5596 16.8931 61.1524 16.8931H61.8995C62.3084 16.8931 62.6817 17.1261 62.8624 17.4941L65.346 22.5528C65.5267 22.9208 65.9001 23.1539 66.309 23.1539H68.7076C69.512 23.1539 70.0306 22.2989 69.661 21.5821L67.433 17.2614C67.1455 16.7039 67.4032 16.0264 67.9037 15.6499C69.2319 14.6507 69.9591 13.004 69.9591 11.2069C69.9591 7.57746 67.6025 5.38466 63.2845 5.38466H56.8637C56.2708 5.38466 55.7903 5.86681 55.7903 6.46158V22.077C55.7903 22.6717 56.2708 23.1539 56.8637 23.1539H59.0056C59.5984 23.1539 60.079 22.6717 60.079 22.077V17.97Z"
      fill="white"
    />
    <path
      d="M86.4594 22.3831C86.5945 22.8403 87.0132 23.1539 87.4885 23.1539H89.7447C90.4789 23.1539 90.9964 22.4309 90.7618 21.7329L85.5137 6.11749C85.3665 5.67955 84.9572 5.38466 84.4965 5.38466H80.8273C80.3667 5.38466 79.9574 5.67955 79.8102 6.11749L74.5621 21.7329C74.3275 22.4309 74.845 23.1539 75.5792 23.1539H77.6744C78.1497 23.1539 78.5684 22.8403 78.7035 22.3831L79.4189 19.9625C79.554 19.5053 79.9727 19.1917 80.448 19.1917H84.7149C85.1902 19.1917 85.6089 19.5053 85.744 19.9625L86.4594 22.3831ZM82.5178 9.13198C82.5251 9.10693 82.5481 9.08973 82.5741 9.08973C82.6002 9.08973 82.6231 9.10693 82.6305 9.13198L84.2458 14.6189C84.4489 15.3089 83.9334 16.0008 83.2163 16.0008H81.9319C81.2148 16.0008 80.6993 15.3089 80.9025 14.6189L82.5178 9.13198Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.724 0C98.7603 0 96.3574 2.41078 96.3574 5.38462V22.6154C96.3574 25.5892 98.7603 28 101.724 28H129.633C132.597 28 135 25.5892 135 22.6154V5.38462C135 2.41077 132.597 0 129.633 0H101.724ZM126.275 21.4894C126.74 22.2058 126.227 23.1539 125.375 23.1539H124.427C124.07 23.1539 123.737 22.9763 123.537 22.68L123.349 22.4005C123.08 22.0006 122.579 21.8369 122.108 21.9344C121.52 22.056 120.901 22.1106 120.257 22.1106C115.644 22.1106 112.754 19.174 112.754 14.2796C112.754 9.47544 115.644 6.46155 120.257 6.46155C124.845 6.46155 127.735 9.47544 127.735 14.2796C127.735 16.4327 127.172 18.2495 126.105 19.5315C125.759 19.9475 125.662 20.5446 125.956 20.9987L126.275 21.4894ZM116.544 14.2796C116.544 17.2162 118.001 19.1096 120.257 19.1096C120.696 19.1096 120.886 18.6204 120.644 18.2526L120.463 17.9765C120.07 17.38 120.497 16.5851 121.209 16.5851H122.581C122.69 16.5851 122.793 16.6352 122.861 16.7211C123.01 16.9097 123.297 16.9 123.41 16.6876C123.761 16.0211 123.958 15.2113 123.958 14.2796C123.958 11.3688 122.501 9.46256 120.257 9.46256C118.001 9.46256 116.544 11.3559 116.544 14.2796ZM108.371 20.7761C108.371 21.3708 107.89 21.853 107.297 21.853H105.73C105.138 21.853 104.657 21.3708 104.657 20.7761V7.79607C104.657 7.20131 105.138 6.71915 105.73 6.71915H107.297C107.89 6.71915 108.371 7.20131 108.371 7.79607V20.7761Z"
      fill="white"
    />
  </svg>
)

export default Logo
