import React from 'react'
import { colors } from '../../styles'

const ArrowBack = ({
  width = 15,
  height = 24,
  fill = colors.white,
}): React.ReactElement => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 24" fill="none">
      <path
        d="M12.8125 2L2.75 12.0625L12.8125 22.125"
        stroke={fill}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowBack
