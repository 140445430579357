import React from 'react'
import { colors } from '../../styles'
import { SvgTypes } from './types'

const CopySquares: React.SFC<SvgTypes> = ({
  size,
  height = 24,
  width = 24,
  fill = colors.white,
}): React.ReactElement => {
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.47372 0C7.3033 0 6.27955 0.68339 5.77948 1.66847C5.46634 2.19645 5.65044 2.878 6.18644 3.17713C6.71155 3.47009 7.37487 3.28374 7.66939 2.75948C7.77065 2.41215 8.08101 2.17882 8.47372 2.17882H20.9798C21.4626 2.17882 21.8171 2.53332 21.8171 3.01625V15.5277C21.8171 15.906 21.5991 16.203 21.2727 16.315C20.7485 16.6096 20.5622 17.274 20.8551 17.7992C21.1542 18.3352 21.8357 18.5193 22.3636 18.2062C23.3318 17.7012 24 16.6848 24 15.5277V3.01625C24 1.36223 22.6336 0 20.9798 0H8.47372ZM3.02024 5.45716C1.36637 5.45716 0 6.81833 0 8.47234V20.9838C0 22.6378 1.36637 24 3.02024 24H15.5263C17.1801 24 18.5465 22.6378 18.5465 20.9838V8.47234C18.5465 6.81833 17.1801 5.45716 15.5263 5.45716H3.02024ZM3.02024 7.63491H15.5263C16.0092 7.63491 16.3636 7.98942 16.3636 8.47234V20.9838C16.3636 21.4667 16.0092 21.8223 15.5263 21.8223H3.02024C2.53736 21.8223 2.18288 21.4667 2.18288 20.9838V8.47234C2.18288 7.98942 2.53736 7.63491 3.02024 7.63491Z"
        fill={fill}
      />
    </svg>
  )
}

export default CopySquares
