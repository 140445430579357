import * as Sentry from '@sentry/browser'
import config from '../config'

const isDev = config.env === 'development'

/* eslint-disable no-console */

class Logger {
  info(message: string, extra?: object): void {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.log(message, extra)
    }
  }

  error(err: Error, extra?: object): void {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.error(err, extra)
    } else {
      Sentry.withScope(scope => {
        if (extra) {
          scope.setExtras(extra as any)
        }

        Sentry.captureException(err)
      })
    }
  }
}

const instance = new Logger()

export default instance
