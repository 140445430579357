import React from 'react'
import styled from 'styled-components'
import { mediaQuery, colors } from '../../styles'

const links = [
  {
    url: 'https://zebraiq.com/privacy',
    label: 'Privacy',
  },
  {
    url: 'https://zebraiq.com/terms',
    label: 'Terms of Service',
  },
  {
    url: 'https://zebraiq.com/careers',
    label: 'Careers',
  },
]

const ColumnRow = styled.div<{ absolute: boolean; left: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  width: 100%;
  left: 0px;

  ${mediaQuery.tablet} {
    flex-direction: row;
    justify-content: ${({ left }) => (left ? 'flex-start' : 'center')};
    position: ${({ absolute }) => (absolute ? 'absolute' : 'relative')};
    bottom: 0px;
  }
`

const LinkItem = styled.h5`
  font-weight: 600;
  font-size: 18px;
  color: ${colors.white};
  margin: 0;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:not(:last-child) {
    padding-right: 0px;
    padding-bottom: 17px;
  }

  &:hover {
    opacity: 1;
  }

  text-align: center;

  ${mediaQuery.tablet} {
    text-align: left;

    &:not(:last-child) {
      padding-right: 17px;
      padding-bottom: 0px;
    }
  }
`

interface Props {
  absolute?: boolean
  left?: boolean
}

const FooterLinks: React.SFC<Props> = ({
  absolute = false,
  left = false,
}): React.ReactElement => {
  return (
    <ColumnRow absolute={absolute} left={left}>
      {links.map(({ url, label }) => (
        <LinkItem key={label}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {label}
          </a>
        </LinkItem>
      ))}
    </ColumnRow>
  )
}

export default FooterLinks
