import React from 'react'
import styled from 'styled-components'
import { mediaQuery, colors } from '../styles'
import { MIN_HEIGHT } from '../components/Main'
import useWindowDimensions from '../hooks/useWindowDimensions'

const Full = styled.div`
  width: 100%;

  ${mediaQuery.tablet} {
    min-height: ${MIN_HEIGHT};
  }

  ${mediaQuery.medium} {
    min-height: ${MIN_HEIGHT};
  }
`

const Container = styled(Full)<{ height: number }>`
  background: linear-gradient(202.81deg, #de16ff -22.4%, #ffa216 111.91%);
  border-radius: 40px;
  height: ${({ height }) => `${height}px`};
`

const Blur = styled(Full)<{ height: number }>`
  @supports (backdrop-filter: none) {
    background: linear-gradient(202.81deg, #de16ff -22.4%, #ffa216 111.91%);
  }

  @supports (backdrop-filter: none) {
    backdrop-filter: blur(250px);
  }

  height: ${({ height }) => `${height}px`};
  overflow: auto;

  ${mediaQuery.medium} {
    height: 100%;
  }
`

const Wrapper = styled(Full)`
  color: ${colors.white};
  display: flex;
  justify-content: flex-start;
  overflow: auto;

  ${mediaQuery.tablet} {
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`

const WrapperInner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-direction: column;
  margin: 0px auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 20px;

  ${mediaQuery.tablet} {
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    height: 80vh;
    min-height: ${MIN_HEIGHT};
  }

  ${mediaQuery.large} {
    width: 90%;
    max-width: 1000px;
    min-width: 860px;
    height: 80vh;
    min-height: ${MIN_HEIGHT};
    width: 90%;
  }
`

const LayoutGradient: React.SFC = ({ children }): React.ReactElement => {
  const { height } = useWindowDimensions()

  return (
    <Container height={height}>
      <Blur height={height}>
        <Wrapper>
          <WrapperInner>{children}</WrapperInner>
        </Wrapper>
      </Blur>
    </Container>
  )
}

export default LayoutGradient
