"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const enums_1 = require("../../enums");
exports.GetUserQueryMockHappy = {
    me: {
        __typename: 'User',
        availableRewardPoints: 0,
        dateOfBirth: null,
        email: null,
        id: '6efed691-4c0b-4075-bf61-a4ac44eb0def',
        lastActiveDate: null,
        name: 'Jackie Wung',
        phoneNumber: '+1234567899',
        totalRewardPoints: 0,
        dailyQuestionStreak: 4,
        dailyQuestionStreakBest: 4,
        dailyQuestionMultiplier: 2,
        preferences: {
            notifications: 'OFTEN',
            location: 'NO',
        },
        profile: {
            nationality: [],
            gender: enums_1.ProfileGender.FEMALE,
            birthSex: enums_1.ProfileBirthSex.MALE,
            orientation: '',
            background: '',
            languagesSpoken: ['spanish', 'english'],
            languagesReading: [],
            languagesWriting: [],
            religion: '',
            politics: '',
            children: '',
            living: '',
            relationship: '',
            pregnant: '',
            household: '',
            school: '',
            education: '',
            employment: '',
            income: '',
        },
    },
};
exports.GetUserQueryMockSad = {
    me: null,
};
