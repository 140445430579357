import styled from 'styled-components'
import {
  background,
  BackgroundProps,
  display,
  DisplayProps,
} from 'styled-system'
import { mediaQuery } from '../../styles'

export const HeaderOpacity = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #ffb7e1;
  margin: 0;
`

export const HeaderLarge = styled.h1`
  font-weight: bold;
  font-size: 35px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  margin: 0;
  text-align: center;

  ${mediaQuery.tablet} {
    text-align: left;
    font-size: 50px;
  }
`

export const HeaderDescription = styled.h4<{ textAlign?: string }>`
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
  padding: 19px 0px 0px 0px;
  text-align: center;

  ${mediaQuery.tablet} {
    text-align: ${({ textAlign }) => textAlign || 'center'};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${mediaQuery.tablet} {
    position: relative;
    height: 100%;
    align-items: center;
    flex-direction: row;
  }
`

export const Column = styled.div<BackgroundProps & DisplayProps>`
  width: 100%;
  padding: 44px 0;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${background};
  ${display};

  ${mediaQuery.tablet} {
    width: 50%;
  }
`

export const FormWrapper = styled.div`
  max-width: 370px;
`

export const Separator = styled.div`
  background-color: #efefef;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`

export const ErrorBox = styled.div`
  color: rgb(97, 26, 21);
  background: rgb(253, 236, 234);
  margin: 10px 0;
  width: 100%;
  padding: 8px 16px;
  font-size: 0.875rem;
  border-radius: 4px;
`

export const MobileLineBreak = styled.br`
  display: block;

  ${mediaQuery.tablet} {
    display: none;
  }
`
