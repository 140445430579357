import styled from 'styled-components'
import { colors } from '../../styles'

export const MenuWrapper = styled.ul`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 90%;
  max-width: 554px;
`

export const MenuItem = styled.li`
  & > a {
    font-size: 0.875rem;
    line-height: 130%;
    opacity: 0.8;
    text-decoration: none;
    color: ${colors.white};
  }
`
