import React from 'react'
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { Roles } from '@zebra-iq/common'
import { useContainer } from 'use-container'
import { AuthStateContainer } from '../data/auth'
import { OrganizationApplicationStateContainer } from '../data/organizationApplication'

const isAuthenticated = (accessToken: string, roles: string[]) => {
  return (
    accessToken &&
    roles &&
    (roles.includes(Roles.ADMIN) ||
      roles.includes(Roles.ORG_ADMIN) ||
      roles.includes(Roles.CUSTOMER))
  )
}

const PrivateRoute: React.FC<any> = ({ children, ...rest }) => {
  const history = useHistory()
  const location = useLocation()
  const authCtx = useContainer(AuthStateContainer)
  const orgAppCtx = useContainer(OrganizationApplicationStateContainer)

  const { roles, user } = authCtx.state

  const isRedirectRoute =
    location.pathname !== '/onboarding' && location.pathname !== '/waitlist'

  let isOnboarding = false
  let isWaitlist = false

  if (
    isRedirectRoute &&
    user &&
    !user.isActive &&
    !user.organizationMemberships.length
  ) {
    if (orgAppCtx.state.organizationApplication) {
      isWaitlist = true
    } else {
      isOnboarding = true
    }
  }

  React.useLayoutEffect((): void => {
    if (isOnboarding) {
      history.push('/onboarding')
    } else if (isWaitlist) {
      history.push('/waitlist')
    }
  })

  if (isOnboarding || isWaitlist) {
    return null
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated(user, roles) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
