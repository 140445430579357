import * as React from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { EventType, track } from '../lib/events'
import { trackPageUrl } from '../lib/analytics'

export const MIN_HEIGHT = '800px'

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`

const Main: React.FC = ({ children }) => {
  const location = useLocation()

  React.useEffect(() => {
    track(EventType.PageViewed, { screen: location.pathname })
    trackPageUrl(location.pathname)
  }, [location.pathname])

  return <Wrapper>{children}</Wrapper>
}

export default Main
