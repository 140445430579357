import { Container } from 'use-container'
import { Roles } from '@zebra-iq/common'
import { bootIntercom, shutdownIntercom } from '../../lib/intercom'
import { track, EventType } from '../../lib/events'

interface IAuth {
  accessToken: string | null
  refreshToken: string | null
  roles: Roles[]
  user: any
}

export const ACCESS_TOKEN_IDENTIFIER = 'auth-access-token'

export const REFRESH_TOKEN_IDENTIFIER = 'auth-refresh-token'

export const ROLES_IDENTIFIER = 'auth-roles'

export const USER_IDENTIFIER = 'auth-user'

export class AuthStateContainer extends Container<IAuth> {
  constructor() {
    super()

    const user = JSON.parse(localStorage.getItem(USER_IDENTIFIER) || 'null')
    this.state = {
      accessToken: localStorage.getItem(ACCESS_TOKEN_IDENTIFIER) || '',
      refreshToken: localStorage.getItem(REFRESH_TOKEN_IDENTIFIER) || '',
      roles: JSON.parse(localStorage.getItem(ROLES_IDENTIFIER) || '[]'),
      user: user || null,
    }

    if (user) {
      bootIntercom(user.id, user.name, user.email, user.intercomUserHash)
    }
  }

  setAccessToken = (accessToken: string | null) =>
    this.setState({ accessToken })

  removeAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_IDENTIFIER)
    this.setState({ accessToken: '' })
  }

  setRefreshToken = (refreshToken: string) => this.setState({ refreshToken })

  setRoles = (roles: Roles[]) => this.setState({ roles })

  setUser = (user: any) => {
    this.setState({ user })
    bootIntercom(user.id, user.name, user.email, user.intercomUserHash)
  }

  login = ({ accessToken, refreshToken, user }: IAuth) => {
    this.setState({
      accessToken,
      refreshToken,
      user,
    })
    track(EventType.UserSignedIn)
  }

  persist = (): void => {
    localStorage.setItem(ACCESS_TOKEN_IDENTIFIER, this.state.accessToken || '')
    localStorage.setItem(
      REFRESH_TOKEN_IDENTIFIER,
      this.state.refreshToken || '',
    )
    localStorage.setItem(ROLES_IDENTIFIER, JSON.stringify(this.state.roles))
    localStorage.setItem(USER_IDENTIFIER, JSON.stringify(this.state.user))
  }

  reset = (): void => {
    localStorage.removeItem(ACCESS_TOKEN_IDENTIFIER)
    localStorage.removeItem(REFRESH_TOKEN_IDENTIFIER)
    localStorage.removeItem(ROLES_IDENTIFIER)
    localStorage.removeItem(USER_IDENTIFIER)

    track(EventType.UserSignedOut)
    shutdownIntercom()

    this.setState({
      accessToken: '',
      refreshToken: '',
      roles: [],
      user: null,
    })
  }
}
