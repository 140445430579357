import React from 'react'
import styled from 'styled-components'
import { InputStyle } from '../styled'
import { FormInput } from '.'

const TextInput = styled.input`
  ${InputStyle}
`

const OnboardingTextInput: React.SFC<FormInput> = ({
  onChange,
  label,
  value,
  minLength = 2,
  maxLength = 255,
  required,
}): React.ReactElement => {
  const handleOnChange = (e: any) => onChange({ [label]: e.target.value })
  return (
    <TextInput
      type="text"
      minLength={minLength}
      maxLength={maxLength}
      value={value}
      id={label}
      name={label}
      onChange={handleOnChange}
      required={required}
      aria-required={required}
    />
  )
}

export default OnboardingTextInput
