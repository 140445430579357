import styled, { css } from 'styled-components'
import {
  WidthProps,
  SpaceProps,
  HeightProps,
  width,
  height,
  space,
} from 'styled-system'
import { variables, gradients, colors } from '../styles'

const Card = styled.div<
  { gradient?: boolean } & WidthProps & SpaceProps & HeightProps
>`
  border-radius: ${variables.borderRadius};

  ${width};
  ${height};
  ${space};
  box-sizing: border-box;
  border: 1px solid #ededed;
  overflow: hidden;
  display: block;

  ${({ gradient }) =>
    gradient
      ? css`
          background: ${gradients.purple};
          border: 1px solid transparent;

          position: relative;
          &:before {
            content: '';

            width: calc(100% - 2px);
            height: calc(100% - 2px);
            position: absolute;
            background: ${colors.white};
            border-radius: calc(${variables.borderRadius} - 0.5px);
            top: 1px;
            left: 1px;
          }
        `
      : css`
          background: ${colors.white};
        `};
`

export default Card
