import * as React from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { useLocation } from 'react-router'
import Landing from './pages/Landing'
import Tiers from './pages/Tiers'

const COMPONENTS = {
  '/settings/tiers': Tiers,
  '/settings': Landing,
}

const Settings = () => {
  const location = useLocation()

  const Component =
    COMPONENTS[location.pathname as keyof typeof COMPONENTS] || Landing

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
        classNames="fade-left"
        timeout={{ enter: 0, exit: 300, appear: 300 }}
      >
        <Component />
      </CSSTransition>
    </SwitchTransition>
  )
}

export default Settings
