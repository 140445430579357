import React from 'react'
import { formValidations } from '../../../../lib/form'
import { OnboardingKeys } from '../../props'
import config from '../../../../config'
import { FormInputCheckboxLabel } from './OnboardingCheckbox'

export const onboardingInputFields = {
  [OnboardingKeys.NAME]: {
    title: 'Name',
    errorMessage: 'Name is required',
    label: OnboardingKeys.NAME,
    input: 'text',
    required: true,
    ...formValidations.name,
  },
  [OnboardingKeys.PHONE_NUMBER]: {
    title: 'Phone Number',
    errorMessage: 'Phone Number is required',
    label: OnboardingKeys.PHONE_NUMBER,
    input: 'phone',
    required: true,
  },
  [OnboardingKeys.INDUSTRY]: {
    title: 'Industry',
    label: OnboardingKeys.INDUSTRY,
    input: 'text',
    required: false,
    ...formValidations.industry,
  },
  [OnboardingKeys.AGENCY_LABEL]: {
    title: 'Agency / Label',
    label: OnboardingKeys.AGENCY_LABEL,
    input: 'text',
    required: false,
    ...formValidations.agencyLabel,
  },
}

export const onboardingCheckboxFields = {
  [OnboardingKeys.IS_CREATOR_MANAGER]: {
    label: OnboardingKeys.IS_CREATOR_MANAGER,
    required: false,
    title: (
      <FormInputCheckboxLabel>
        I am this <b>creator&apos;s manager</b>
      </FormInputCheckboxLabel>
    ),
  },
  [OnboardingKeys.TERMS_AGREED]: {
    label: OnboardingKeys.TERMS_AGREED,
    errorMessage: 'Please accept the Terms of Service',
    required: true,
    title: (
      <FormInputCheckboxLabel>
        I agree to the{' '}
        <b>
          <a
            href={config.links.termsOfService}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
        </b>
      </FormInputCheckboxLabel>
    ),
  },
}

export const onboardingForm = {
  title: 'Tell us about yourself',
  inputFields: Object.values(onboardingInputFields),
  checkboxFields: Object.values(onboardingCheckboxFields),
  buttonText: 'Submit Waitlist',
}
