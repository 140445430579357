import React from 'react'
import { Redirect, Route } from 'react-router'
import PrivateRoute from '../components/PrivateRoute'
import { RouteType, privateRoutes, publicRoutes } from '.'

interface RouteInner {
  component?: any
  redirectRoute?: any
}

interface RouteDestructure {
  component?: any
  redirectRoute?: string
  route: string
}

const getRouteInner = ({
  component,
  redirectRoute,
}: RouteInner): React.ReactElement => {
  const Component = component ? React.createElement(component) : null
  return Component || <Redirect to={redirectRoute as string} />
}

const getPrivateRoute = ({
  component,
  redirectRoute,
  route,
}: RouteDestructure): React.ReactElement => (
  <PrivateRoute exact path={route} key={redirectRoute || route}>
    {getRouteInner({ component, redirectRoute })}
  </PrivateRoute>
)

const getPublicRoute = ({
  component,
  redirectRoute,
  route,
}: RouteDestructure): React.ReactElement => (
  <Route exact path={route} key={redirectRoute || route}>
    {getRouteInner({ component, redirectRoute })}
  </Route>
)

const destructureRoutes = (
  routes: { [key: string]: RouteType },
  routeDestructureFunc: (args: RouteDestructure) => React.ReactElement,
): React.ReactElement[] => {
  const routesList: RouteType[] = Object.values(routes)

  const routeElements: React.ReactElement[] = []

  routesList.forEach(({ route, component, redirectsFrom }) => {
    if (redirectsFrom) {
      redirectsFrom.forEach((redirectFromRoute: string) => {
        routeElements.push(
          routeDestructureFunc({
            redirectRoute: route,
            route: redirectFromRoute,
          }),
        )
      })
    }

    routeElements.push(routeDestructureFunc({ route, component }))
  })

  return routeElements
}

export const getPrivateRoutes = (): React.ReactElement[] => {
  return destructureRoutes(privateRoutes, getPrivateRoute)
}

export const getPublicRoutes = (): React.ReactElement[] => {
  return destructureRoutes(publicRoutes, getPublicRoute)
}

export const getRoutesStringList = (routes: {
  [key: string]: RouteType
}): string[] => Object.values(routes).map(route => route.route)
