import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import { ThemeProvider } from 'styled-components'
import * as serviceWorker from './serviceWorker'
import { GlobalStyles } from './styles/GlobalStyles'
import App from './pages/App'
import './lib/firebase'
import StripeElementsProvider from './components/StripeElementsProvider'
import client from './data/apollo/client'
import { breakpoints } from './styles'
import config from './config'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: config.sentry.dsn })
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <StripeElementsProvider>
      <ThemeProvider theme={{ breakpoints }}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </StripeElementsProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
