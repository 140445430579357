import React from 'react'
import styled from 'styled-components'
import Box from '../../components/Box'
import { gradients, mediaQuery } from '../../styles'
import LogoSymbol from '../../components/Svg/LogoSymbol'
import {
  HeaderOpacity,
  HeaderLarge,
  HeaderDescription,
  Wrapper,
} from './styled'
import { AuthProps } from './props'
import AuthSlideshow from './components/AuthSlideshow'

const ColumnLeft = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`

const ColumnRight = styled.div`
  flex: 2;
  height: auto;
  height: 100%;
  width: 100%;
  padding-left: 0px;
  position: relative;

  ${mediaQuery.tablet} {
    padding-left: 50px;
  }

  ${mediaQuery.medium} {
    padding-left: 99px;
  }
`

const ButtonSignUp = styled.button`
  background: #fff;
  border: 0;
  border-radius: 12px;
  padding: 14px 18px;
  cursor: pointer;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 19px;
`

const ButtonText = styled.h4`
  font-weight: 600;
  font-size: 19px;
  margin: 0;
  background: -webkit-${gradients.pinkOrange}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ButtonLogo = styled.span`
  padding-left: 8px;
  top: 2px;
  position: relative;
`

const Relative = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrapperPadding = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0px;

  ${mediaQuery.tablet} {
    align-items: flex-start;
  }
`

const AuthLanding: React.FC<AuthProps> = ({ onNext }) => {
  return (
    <Wrapper>
      <ColumnLeft>
        <AuthSlideshow />
      </ColumnLeft>

      <ColumnRight>
        <Relative>
          <>
            <WrapperPadding>
              <Box paddingBottom="5px">
                <HeaderOpacity>for Creators</HeaderOpacity>
              </Box>
              <HeaderLarge>Discover your superfans</HeaderLarge>
              <HeaderDescription textAlign="left">
                Convert your superfans into a million dollar business.
              </HeaderDescription>

              <ButtonSignUp onClick={onNext}>
                <ButtonText>Sign Up for</ButtonText>
                <ButtonLogo>
                  <LogoSymbol size={22} />
                </ButtonLogo>
              </ButtonSignUp>
            </WrapperPadding>
          </>
        </Relative>
      </ColumnRight>
    </Wrapper>
  )
}

export default AuthLanding
