import React from 'react'
import styled from 'styled-components'
import { gradients } from '../../../styles'

const ButtonWrapper = styled.button`
  background: #fff;
  border: 0;
  border-radius: 12px;
  padding: 12px 36px;
  cursor: pointer;
`

const ButtonText = styled.h4`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  background: -webkit-${gradients.pinkOrange}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

interface Props {
  onClick: (e?: any) => void
  isFirstStep: boolean
}

const OnboardingNextButton: React.SFC<Props> = ({
  isFirstStep,
  onClick,
}): React.ReactElement => {
  return (
    <ButtonWrapper onClick={onClick}>
      <ButtonText>{isFirstStep ? 'Start' : 'Next'}</ButtonText>
    </ButtonWrapper>
  )
}

export default OnboardingNextButton
