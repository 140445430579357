import { useEffect } from 'react'
import config from '../config'

const JAM_SNIPPET_ID = 'jam-snippet'

interface JamType {
  init(str: string): void
}

declare global {
  interface Window {
    Jam: JamType
  }
}

const useJam = (): void => {
  useEffect(() => {
    if (!config.jam.enable) {
      return
    }

    const existingScript = document.getElementById(JAM_SNIPPET_ID)
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = 'https://jam.dev/jam.js'
      script.id = JAM_SNIPPET_ID
      document.body.appendChild(script)
      script.onload = () => {
        window.Jam.init(config.jam.projectId)
      }
    }
  }, [])
}

export default useJam
