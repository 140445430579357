import * as React from 'react'
import { Link } from 'react-router-dom'
import { MenuWrapper, MenuItem } from './styled'

export const MENU_LINKS = [
  {
    label: 'Home',
    url: '/',
    emoji: '🏡',
  },
  {
    label: 'Press',
    url: 'https://zebraiq.com/press/',
    emoji: '🗞',
  },
  {
    label: 'Download',
    url: 'https://zebraiq.com/app/',
    emoji: '📲',
  },
  {
    label: 'For Brands',
    url: 'https://zebraiq.com/',
    emoji: '🚀',
  },
  {
    label: 'Terms of Use',
    url: 'https://zebraiq.com/terms/',
    emoji: '📄',
  },
  {
    label: 'Privacy Policy',
    url: 'https://zebraiq.com/privacy/',
    emoji: '🔒',
  },
]

const Menu: React.FC = () => (
  <MenuWrapper>
    {MENU_LINKS.map(link => (
      <MenuItem key={link.label}>
        {link.url.includes('http') ? (
          <a href={link.url}>{link.label}</a>
        ) : (
          <Link to={link.url}>{link.label}</Link>
        )}
      </MenuItem>
    ))}
  </MenuWrapper>
)

export default Menu
