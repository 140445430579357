import styled from 'styled-components'

export const Section = styled.div`
  position: relative;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 24px;
    width: 6px;
    height: calc(100% - 24px);
    background: #ebebeb;
    border-radius: 20px;
  }
`

export const SectionHeader = styled(Section)`
  margin-left: -20px;

  & * {
    color: #8d8d8d;
  }

  &::before {
    background: #8d8d8d;
    height: 100%;
    top: 0%;
  }
`
