import React from 'react'
import styled from 'styled-components'
import PaginationDot from './PaginationDot'

const Container = styled.div`
  position: absolute;
  bottom: 8;
  right: 8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`

interface Props {
  dots: number
  index: number
  onChangeIndex: (index: number) => void
}

const Pagination: React.SFC<Props> = ({
  dots,
  index,
  onChangeIndex,
}): React.ReactElement => {
  const handleClick = (index: number) => {
    onChangeIndex(index)
  }

  return (
    <Container>
      {Array(dots)
        .fill('')
        .map((_: any, i: number) => (
          <PaginationDot
            key={i}
            index={i}
            active={i === index}
            onClick={handleClick}
          />
        ))}
    </Container>
  )
}

export default Pagination
