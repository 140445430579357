export * from './mediaQuery'

export const gradients = {
  purple:
    'radial-gradient(268.53% 196.36% at -23.86% 100%, #FF5ED2 0%, #8F68FF 50.52%, #4F75FF 100%)',

  progress: 'linear-gradient(to right, #ff5ed2 0%, #8f68ff 100%)',

  purpleLight:
    'radial-gradient(268.53% 196.36% at -23.86% 100%, rgba(255, 94, 210, 0.2) 0%, rgba(143, 104, 255, 0.2) 50.52%, rgba(79, 117, 255, 0.2) 100%)',

  orange: 'linear-gradient(154.46deg, #FFF4BA -65.61%, #E17C1F 105.43%)',

  red: 'linear-gradient(157.27deg, #FFD0D0 -99.41%, #F22C2C 116.28%)',

  pinkOrange: `linear-gradient(#DE16FF -22.4%, #FFA216 111.91%);`,
}

export const colors = {
  white: '#fff',
  purple: '#4f75ff',

  header: '#5b5b5b',
  primaryText: '#727272',
  placeholder: '#989898',
  spacedText: '#b5b5b5',
  labelText: '#636363',

  disabled: '#ececec',
  disabledText: '#a5a5a5',

  gray: '#6b6b6b',
}

export const variables = {
  borderRadius: '10px',
}
