import * as React from 'react'
import Radio from './Input/components/Radio'
import Card from './Card'
import Box from './Box'
import Tag from './Tag'
import { SmallText } from './Typography'
import List from './List'

const listItems = [
  'This is another feature.',
  'This is a third feature.',
  'Can you believe a fourth?',
  'Now here’s a fifth.',
  'No, this isn’t a sixth.',
  'Are you ready for seven?',
  'Eight ate my cat.',
  'Nine is another number.',
]

interface SubscriptionPlan {
  id: string
  name: string
  amount: number
}

const plans: SubscriptionPlan[] = [
  {
    amount: 50000,
    id: 'basic',
    name: 'Basic',
  },
  {
    amount: 150000,
    id: 'premium',
    name: 'Premium',
  },
  {
    amount: 500000,
    id: 'extreme',
    name: 'Extreme',
  },
]

interface Props {
  value?: string
  onChange: (val: string) => void
}

const PlansRadio: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Radio
      value={plans.find(plan => plan.id === value)?.name || plans[0].name}
      onChange={val =>
        onChange(plans.find(plan => plan.name === val)?.id || '')
      }
      options={plans.map(plan => plan.name)}
      renderEntry={(input, index) => (
        <Card
          as="label"
          p={16}
          width={220}
          height={304}
          mr={16}
          gradient={plans[index].id === value}
        >
          <Box position="relative">
            <Box mb={12} display="flex" justifyContent="space-between">
              {input}
              <Tag gradient={plans[index].id === value}>
                <SmallText bold>
                  ${(plans[index].amount / 100).toLocaleString()}
                </SmallText>
                <SmallText> / mo</SmallText>
              </Tag>
            </Box>
            <List header="This is the header" items={listItems} />
          </Box>
        </Card>
      )}
    />
  )
}

export default PlansRadio
