import React from 'react'
import styled from 'styled-components'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useContainer } from 'use-container'
import { useHistory } from 'react-router'
import { mediaQuery, colors, gradients } from '../../styles'
import { OnboardingStepProps } from '../Onboarding/props'
import LayoutGradient from '../../layout/LayoutGradient'
import HeaderNavigation from '../../components/HeaderNavigation'
import { OnboardingInfoContainer } from '../Onboarding/styled'
import SpeechBubble, {
  SPEECH_BUBBLE_WIDTH,
} from '../../components/SpeechBubble'
import { Heading4 } from '../../components/Typography'
import HelpCircle from '../../components/Svg/HelpCircle'
import { HeaderLarge } from '../Auth/styled'
import Box from '../../components/Box'
import ButtonGradientText from '../../components/ButtonGradientText'
import CopySquares from '../../components/Svg/CopySquares'
import { AuthStateContainer } from '../../data/auth'
import useGetOrganizationApplication from '../../hooks/useGetOrganizationApplication'
import useReferralCode from '../../hooks/useReferralCode'
import { OrganizationApplicationStateContainer } from '../../data/organizationApplication'
import { trackEvent } from '../../lib/analytics'

const CALENDLY_ONBOARDING_LINK = 'https://calendly.com/zebraiq/onboarding'

const HelpWrapper = styled.div`
  padding-left: 10px;
  cursor: pointer;
  position: relative;
  top: 3px;
  display: none;

  ${mediaQuery.large} {
    display: block;
  }
`

const HelpMobileContainer = styled.div`
  display: block;

  ${mediaQuery.large} {
    display: none;
  }
`

const WrapperWaitlist = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQuery.tablet} {
    padding: 60px 0px;
    flex-direction: row;
  }
`

const ContainerNumber = styled.div`
  background: ${colors.white};
  border-radius: 32px;
  padding: 32px;
`

const Number = styled.h1`
  margin: 0;
  font-size: 88px;
  background: -webkit-${gradients.pinkOrange}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const NumberDescription = styled.h4`
  margin: 0;
  padding: 10px;
  font-weight: 600;
  font-size: 22px;
  color: #9f9f9f;
  opacity: 0.7;
`

const Column = styled.div`
  flex: 1;
  width: 100%;
`

const NumberColumn = styled(Column)`
  cursor: pointer;
  ${mediaQuery.tablet} {
    max-width: 296px;
  }
`

const RightColumn = styled(Column)`
  padding-left: 0px;
  padding-top: 50px;
  text-align: center;

  ${mediaQuery.tablet} {
    padding-top: 0px;
    padding-left: 50px;
    text-align: left;
  }
`

const Description = styled.h5`
  padding: 7px 0px 20px;
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
  font-weight: 300;
`

const WrapperLeftCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;

  ${mediaQuery.tablet} {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: auto;
    margin: 0;
  }
`

const ReferralInput = styled.input`
  cursor: pointer;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: inherit;
  font-weight: 600;
  padding: 16px;
  border: 0px;
  background: rgba(105, 8, 39, 0.15);
  border-radius: 12px;
`

const CopyContainer = styled.div<{ isCopied: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  opacity: 0.5;
  transition: all 0.2s;
  padding-top: 20px;
  padding-left: 0px;

  &:hover {
    opacity: 1;
  }

  ${mediaQuery.large} {
    padding-top: 0px;
    padding-left: 24px;
  }
`

const CopyText = styled.h5`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0;
  padding-left: 9px;
`

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${mediaQuery.large} {
    flex-direction: row;
  }
`

const SpeechContainer = styled.div`
  top: -20px;
  position: relative;
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.tablet} {
    flex-direction: row;
  }
`

const ButtonTimeWrapper = styled.div`
  padding-left: 0px;

  ${mediaQuery.tablet} {
    padding-left: 20px;
  }
`

const FriendText = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  width: 100%;

  ${mediaQuery.large} {
    text-align: left;
  }
`

const Waitlist: React.SFC<OnboardingStepProps> = (): React.ReactElement => {
  const authCtx = useContainer(AuthStateContainer)
  const orgAppCtx = useContainer(OrganizationApplicationStateContainer)
  const history = useHistory()

  const [isReferralCodeSet, setIsReferralCodeSet] = React.useState<boolean>(
    false,
  )
  const [showMessage, setShowMessage] = React.useState<boolean>(false)
  const [isCopied, setIsCopied] = React.useState<boolean>(false)

  const handleOnBack = () => {
    trackEvent('Pressing back from waitlist')

    authCtx.reset()
    orgAppCtx.reset()
    history.push('/')
  }

  const [getOrganization, { data }] = useGetOrganizationApplication({
    onCompleted(data) {
      if (!data) {
        handleOnBack()
      }
    },
    onError() {
      handleOnBack()
    },
  })

  const organizationApplication =
    data || orgAppCtx.state.organizationApplication

  const handleCopyReferral = (): void => {
    trackEvent('Copied referral', {
      referralCode: organizationApplication
        ? organizationApplication.referralURL
        : '',
    })
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  React.useEffect(() => {
    if (isReferralCodeSet && !organizationApplication) {
      getOrganization()
    }
  }, [getOrganization, isReferralCodeSet, organizationApplication])

  // checks referral code on load
  useReferralCode(() => {
    // determine if navigating away or staying on page by fetching org
    // if a user, fetch organization application
    if (!authCtx.state.user) {
      history.push('/auth')
    }

    setIsReferralCodeSet(true)
  })

  const referralURL = organizationApplication
    ? organizationApplication.referralURL
    : ''

  return (
    <LayoutGradient>
      <HeaderNavigation onBack={handleOnBack} footer>
        <OnboardingInfoContainer>
          <Box display="flex" flexDirection="row" alignItems="center">
            <HeaderLarge>You’re on the waitlist.</HeaderLarge>

            <HelpWrapper
              onMouseEnter={() => setShowMessage(true)}
              onMouseLeave={() => setShowMessage(false)}
            >
              <SpeechContainer>
                <SpeechBubble
                  isVisible={Boolean(showMessage)}
                  label={
                    <>
                      Zebra is <b>invite-only</b>,{` `}
                      for now. We are rolling out invites in stages.
                    </>
                  }
                  left={`-${SPEECH_BUBBLE_WIDTH / 2 - 5}px`}
                />
              </SpeechContainer>

              <HelpCircle />
            </HelpWrapper>
          </Box>
          <HelpMobileContainer>
            <p>
              Zebra is <b>invite-only</b>,{` `}
              for now. We are rolling out invites in stages.
            </p>
          </HelpMobileContainer>

          <WrapperWaitlist>
            <NumberColumn>
              <ContainerNumber>
                <Number>
                  {organizationApplication
                    ? organizationApplication.placeInLine
                    : '?'}
                </Number>
                <NumberDescription>ahead of you</NumberDescription>
              </ContainerNumber>
            </NumberColumn>

            <RightColumn>
              <WrapperLeftCenter>
                <Heading4 color="inherit" textAlign="left">
                  Skip to the front!
                </Heading4>

                <Description>
                  For every creator who signs up using your link, you’ll{' '}
                  <b>skip ahead</b>
                  <span> by a few spots.</span>
                </Description>

                <CopyWrapper>
                  <CopyToClipboard
                    text={referralURL}
                    onCopy={handleCopyReferral}
                  >
                    <div>
                      <ReferralInput value={referralURL} disabled />
                    </div>
                  </CopyToClipboard>

                  <CopyToClipboard
                    text={referralURL}
                    onCopy={handleCopyReferral}
                  >
                    <CopyContainer isCopied={isCopied}>
                      <CopySquares />
                      <CopyText>{isCopied ? 'Copied!' : 'Copy'}</CopyText>
                    </CopyContainer>
                  </CopyToClipboard>
                </CopyWrapper>

                <FriendText>
                  {organizationApplication &&
                  organizationApplication.referralCount !== undefined
                    ? `${
                        organizationApplication.referralCount > 0
                          ? organizationApplication.referralCount
                          : 'No'
                      } friends have signed up`
                    : null}
                </FriendText>
              </WrapperLeftCenter>
            </RightColumn>
          </WrapperWaitlist>

          <TimeWrapper>
            <WrapperLeftCenter>
              <Heading4 color="inherit">Want to go live sooner?</Heading4>

              <Description>
                Schedule a personalized session with us.
              </Description>
            </WrapperLeftCenter>

            <ButtonTimeWrapper>
              <a
                href={CALENDLY_ONBOARDING_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonGradientText label="Find a time" />
              </a>
            </ButtonTimeWrapper>
          </TimeWrapper>
        </OnboardingInfoContainer>
      </HeaderNavigation>
    </LayoutGradient>
  )
}

export default Waitlist
