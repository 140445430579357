import * as React from 'react'

const Google = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g clipPath="url(#google-clip)">
      <path
        d="M16 8.69888C16 8.15506 15.955 7.6083 15.8591 7.0733H8.16046V10.154H12.5691C12.3862 11.1475 11.7983 12.0265 10.9376 12.585V14.5839H13.5678C15.1123 13.1905 16 11.1328 16 8.69888Z"
        fill="#4285F4"
      />
      <path
        d="M8.16048 16.5151C10.3618 16.5151 12.2182 15.8067 13.5708 14.5839L10.9406 12.585C10.2088 13.0729 9.26414 13.3492 8.16348 13.3492C6.03415 13.3492 4.22871 11.9412 3.58091 10.0481H0.86676V12.1088C2.25233 14.8102 5.07445 16.5151 8.16048 16.5151Z"
        fill="#34A853"
      />
      <path
        d="M3.57786 10.0481C3.23596 9.05457 3.23596 7.9787 3.57786 6.98513V4.9245H0.866703C-0.290935 7.18502 -0.290935 9.84825 0.866703 12.1088L3.57786 10.0481Z"
        fill="#FBBC04"
      />
      <path
        d="M8.16047 3.68106C9.32411 3.66342 10.4488 4.0926 11.2915 4.8804L13.6218 2.59636C12.1462 1.23829 10.1878 0.491641 8.16047 0.515157C5.07444 0.515157 2.25233 2.2201 0.86676 4.92449L3.57791 6.98512C4.22271 5.08911 6.03114 3.68106 8.16047 3.68106Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="google-clip">
        <rect y="0.515152" width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Google
