import React from 'react'
import styled from 'styled-components'
import Google from '../Svg/Google'
import { colors } from '../../styles'
import Box from '../Box'

interface Props {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  ) => void
}

const Button = styled.button`
  cursor: pointer;
  background: ${colors.white};
  border: 0px;
  border-radius: 12px;
  padding: 13px 15px;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: ${colors.gray};
  width: 100%;
  display: flex;
  justify-content: center;
`

const Content = styled.span`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
  }
`

const SignInGoogleButton: React.FC<Props> = ({
  onClick,
}): React.ReactElement => {
  return (
    <Button onClick={onClick}>
      <Content>
        Sign up with
        <Box paddingLeft="8px" position="relative" top="2px">
          <Google />
        </Box>
      </Content>
    </Button>
  )
}

export default SignInGoogleButton
