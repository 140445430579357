"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListAssignmentsQueryHappyFinished = {
    listAssignments: [
        {
            __typename: 'Assignment',
            assignedAt: '2019-09-26T03:09:56.279Z',
            finishedAt: '2019-09-25T20:10:38.792Z',
            id: 'b1956d8d-275a-41e5-a79c-426dc3d0c67a',
            nextQuestion: null,
            survey: {
                __typename: 'Survey',
                id: '6a96f431-15f7-4066-8b76-42c0d400931f',
                name: 'What is your favorite animal?',
                rewardPoints: 10,
            },
        },
        {
            __typename: 'Assignment',
            assignedAt: '2019-09-26T03:09:56.279Z',
            finishedAt: '2019-09-25T20:10:38.792Z',
            id: 'b1956d8d-275a-41e5-a79c-426dc3d0c67b',
            nextQuestion: null,
            survey: {
                __typename: 'Survey',
                id: '6a96f431-15f7-4066-8b76-42c0d400931z',
                name: 'What is your favorite food?',
                rewardPoints: 0,
            },
        },
        {
            __typename: 'Assignment',
            assignedAt: '2019-09-26T03:09:56.279Z',
            finishedAt: '2019-09-25T20:10:38.792Z',
            dailyQuestionOn: '2019-09-25T20:10:38.792Z',
            id: 'b1956d8d-275a-41e5-a79c-426dc3d0c67g',
            nextQuestion: null,
            survey: {
                __typename: 'Survey',
                id: '6a96f431-15f7-4066-8b76-42c0d400931ze',
                name: 'Daily Question',
                rewardPoints: 0,
            },
        },
    ],
};
exports.ListAssignmentsQueryHappy = {
    listAssignments: [
        {
            __typename: 'Assignment',
            assignedAt: '2019-09-26T09:26:52.139Z',
            finishedAt: null,
            id: '30a2ea76-a7f8-4d96-86e6-2b0e3a94463c',
            nextQuestion: {
                __typename: 'Question',
                allowAnswerTypes: ['OPTION', 'TEXT'],
                allowMultipleAnswers: false,
                preferences: {
                    rotateOptions: true,
                },
                id: '30866409-7954-41f1-9346-df0833c6670f',
                options: [
                    {
                        __typename: 'Option',
                        text: 'Zebra',
                        url: '',
                    },
                    {
                        __typename: 'Option',
                        text: 'Rabbit',
                        url: '',
                    },
                    {
                        __typename: 'Option',
                        text: 'Antelope',
                        url: '',
                    },
                    {
                        __typename: 'Option',
                        text: 'Walrus',
                        url: '',
                    },
                ],
                prompt: 'What is your favorite animal?',
            },
            survey: {
                __typename: 'Survey',
                id: '2a3ee6cc-2f1f-45f0-82e4-d126a3e7ead7',
                name: 'What is your favorite animal?',
                rewardPoints: 10,
            },
        },
        {
            __typename: 'Assignment',
            assignedAt: '2019-09-26T03:09:56.279Z',
            finishedAt: '2019-09-25T20:10:38.792Z',
            dailyQuestionOn: '2019-09-25T20:10:38.792Z',
            id: 'b1956d8d-275a-41e5-a79c-426dc3d0c67g',
            nextQuestion: null,
            survey: {
                __typename: 'Survey',
                id: '6a96f431-15f7-4066-8b76-42c0d400931ze',
                name: 'Daily Question',
                rewardPoints: 0,
            },
        },
    ],
};
