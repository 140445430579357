import * as React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from '../config'

interface StripeElementsProviderProps {
  children: React.ReactNode
}

const stripePromise = loadStripe(config.stripe.key)

const StripeElementsProvider: React.FC<StripeElementsProviderProps> = ({
  children,
}: StripeElementsProviderProps) => {
  return <Elements stripe={stripePromise}>{children}</Elements>
}

export default StripeElementsProvider
