"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMinimumVersionsQueryHappy = {
    getMinimumVersions: {
        ios: '2.0.1',
        android: '1.0',
    },
};
exports.GetMinimumVersionsQuerySadAndroid = {
    getMinimumVersions: {
        ios: '2.0.1',
        android: null,
    },
};
exports.GetMinimumVersionsQuerySadIos = {
    getMinimumVersions: {
        ios: null,
        android: '1.0.1',
    },
};
exports.GetMinimumVersionsQuerySad = {
    getMinimumVersions: {
        ios: null,
        android: null,
    },
};
