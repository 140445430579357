import styled from 'styled-components'
import { SpaceProps, space, WidthProps, width } from 'styled-system'
import { colors, variables } from '../../../styles'

export const TextInput = styled.input<SpaceProps & WidthProps>`
  width: 100%;
  font-size: 0.875rem;
  padding: 0 12px;

  color: ${colors.primaryText};

  border: 1px solid #eaeaea;
  height: 35px;
  border-radius: ${variables.borderRadius};

  ${space};
  ${width};

  &::placeholder {
    color: ${colors.placeholder};
  }
`
