"use strict";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
/**
 * ActionTypeEnum desc
 */
var ActionTypeEnum;
(function (ActionTypeEnum) {
    ActionTypeEnum["INVITE_USER"] = "INVITE_USER";
    ActionTypeEnum["SHARE_COMMUNITY"] = "SHARE_COMMUNITY";
})(ActionTypeEnum = exports.ActionTypeEnum || (exports.ActionTypeEnum = {}));
/**
 * AgeVerificationStatusEnumDesc
 */
var AgeVerificationStatusEnum;
(function (AgeVerificationStatusEnum) {
    AgeVerificationStatusEnum["ACCEPTED"] = "ACCEPTED";
    AgeVerificationStatusEnum["LOCKED"] = "LOCKED";
    AgeVerificationStatusEnum["PENDING"] = "PENDING";
    AgeVerificationStatusEnum["REJECTED"] = "REJECTED";
    AgeVerificationStatusEnum["REQUIRES_ID_VERIFICATION"] = "REQUIRES_ID_VERIFICATION";
    AgeVerificationStatusEnum["REQUIRES_PARENT_CONSENT"] = "REQUIRES_PARENT_CONSENT";
})(AgeVerificationStatusEnum = exports.AgeVerificationStatusEnum || (exports.AgeVerificationStatusEnum = {}));
/**
 * AnchorType enum
 */
var AnchorType;
(function (AnchorType) {
    AnchorType["AUDIO"] = "AUDIO";
    AnchorType["IMAGE"] = "IMAGE";
    AnchorType["VIDEO"] = "VIDEO";
})(AnchorType = exports.AnchorType || (exports.AnchorType = {}));
/**
 * AnswerType enum
 */
var AnswerType;
(function (AnswerType) {
    AnswerType["AUDIO"] = "AUDIO";
    AnswerType["BOOLEAN"] = "BOOLEAN";
    AnswerType["IMAGE"] = "IMAGE";
    AnswerType["OPTION"] = "OPTION";
    AnswerType["RATING"] = "RATING";
    AnswerType["SKIP"] = "SKIP";
    AnswerType["TEXT"] = "TEXT";
    AnswerType["URL"] = "URL";
    AnswerType["VIDEO"] = "VIDEO";
})(AnswerType = exports.AnswerType || (exports.AnswerType = {}));
/**
 * AssetType enum
 */
var AssetType;
(function (AssetType) {
    AssetType["AGE_VERIFICATION"] = "AGE_VERIFICATION";
    AssetType["ANSWER"] = "ANSWER";
    AssetType["USER"] = "USER";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
/**
 * CashOutProviderEnum
 */
var CashOutProvider;
(function (CashOutProvider) {
    CashOutProvider["AMAZON_CAD"] = "AMAZON_CAD";
    CashOutProvider["AMAZON_USD"] = "AMAZON_USD";
    CashOutProvider["BTC"] = "BTC";
    CashOutProvider["PAYPAL_CAD"] = "PAYPAL_CAD";
    CashOutProvider["PAYPAL_USD"] = "PAYPAL_USD";
    CashOutProvider["PHYSICAL_CARD"] = "PHYSICAL_CARD";
    CashOutProvider["VIRTUAL_CARD"] = "VIRTUAL_CARD";
    CashOutProvider["VIRTUAL_CARD_CAD"] = "VIRTUAL_CARD_CAD";
})(CashOutProvider = exports.CashOutProvider || (exports.CashOutProvider = {}));
/**
 * CashOutStatusEnum
 */
var CashOutStatus;
(function (CashOutStatus) {
    CashOutStatus["APPROVED"] = "APPROVED";
    CashOutStatus["DENIED"] = "DENIED";
    CashOutStatus["PENDING"] = "PENDING";
})(CashOutStatus = exports.CashOutStatus || (exports.CashOutStatus = {}));
/**
 * CommunityMembershipsStatus enum
 */
var CommunityMembershipsStatus;
(function (CommunityMembershipsStatus) {
    CommunityMembershipsStatus["APPLIED"] = "APPLIED";
    CommunityMembershipsStatus["APPLYING"] = "APPLYING";
    CommunityMembershipsStatus["JOINED"] = "JOINED";
    CommunityMembershipsStatus["LEFT"] = "LEFT";
    CommunityMembershipsStatus["RECOMMENDED"] = "RECOMMENDED";
})(CommunityMembershipsStatus = exports.CommunityMembershipsStatus || (exports.CommunityMembershipsStatus = {}));
/**
 * CommunityProfileLinkType enum
 */
var CommunityProfileLinkTypeEnum;
(function (CommunityProfileLinkTypeEnum) {
    CommunityProfileLinkTypeEnum["CUSTOM"] = "CUSTOM";
    CommunityProfileLinkTypeEnum["INSTAGRAM"] = "INSTAGRAM";
    CommunityProfileLinkTypeEnum["SPOTIFY"] = "SPOTIFY";
    CommunityProfileLinkTypeEnum["TIKTOK"] = "TIKTOK";
    CommunityProfileLinkTypeEnum["TWITTER"] = "TWITTER";
    CommunityProfileLinkTypeEnum["YOUTUBE"] = "YOUTUBE";
})(CommunityProfileLinkTypeEnum = exports.CommunityProfileLinkTypeEnum || (exports.CommunityProfileLinkTypeEnum = {}));
/**
 * CommunityProfileTheme enum
 */
var CommunityProfileTheme;
(function (CommunityProfileTheme) {
    CommunityProfileTheme["DEFAULT"] = "DEFAULT";
})(CommunityProfileTheme = exports.CommunityProfileTheme || (exports.CommunityProfileTheme = {}));
/**
 * CompletionActionTypeEnum desc
 */
var CompletionActionTypeEnum;
(function (CompletionActionTypeEnum) {
    CompletionActionTypeEnum["ASSIGN_SURVEY"] = "ASSIGN_SURVEY";
})(CompletionActionTypeEnum = exports.CompletionActionTypeEnum || (exports.CompletionActionTypeEnum = {}));
/**
 * DeleteUserScheduleStatus enum
 */
var DeleteUserScheduleStatus;
(function (DeleteUserScheduleStatus) {
    DeleteUserScheduleStatus["FINISHED"] = "FINISHED";
    DeleteUserScheduleStatus["QUEUED"] = "QUEUED";
})(DeleteUserScheduleStatus = exports.DeleteUserScheduleStatus || (exports.DeleteUserScheduleStatus = {}));
/**
 * DeviceType enum
 */
var DeviceType;
(function (DeviceType) {
    DeviceType["ANDROID"] = "ANDROID";
    DeviceType["IOS"] = "IOS";
    DeviceType["WEB"] = "WEB";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
/**
 * OptionOrdering enum
 */
var OptionOrdering;
(function (OptionOrdering) {
    OptionOrdering["BOTTOM"] = "BOTTOM";
    OptionOrdering["TOP"] = "TOP";
})(OptionOrdering = exports.OptionOrdering || (exports.OptionOrdering = {}));
/**
 * PipedOptionConditionDesc
 */
var PipedOptionConditionEnum;
(function (PipedOptionConditionEnum) {
    PipedOptionConditionEnum["SELECTED"] = "SELECTED";
    PipedOptionConditionEnum["UNSELECTED"] = "UNSELECTED";
})(PipedOptionConditionEnum = exports.PipedOptionConditionEnum || (exports.PipedOptionConditionEnum = {}));
/**
 * PostAssetEnum desc
 */
var PostAssetEnum;
(function (PostAssetEnum) {
    PostAssetEnum["AUDIO"] = "AUDIO";
    PostAssetEnum["IMAGE"] = "IMAGE";
    PostAssetEnum["VIDEO"] = "VIDEO";
})(PostAssetEnum = exports.PostAssetEnum || (exports.PostAssetEnum = {}));
/**
 * ProfileFieldComparator enum
 */
var ProfileFieldComparator;
(function (ProfileFieldComparator) {
    ProfileFieldComparator["EQUAL"] = "EQUAL";
    ProfileFieldComparator["FALSE"] = "FALSE";
    ProfileFieldComparator["GREATER_THAN"] = "GREATER_THAN";
    ProfileFieldComparator["GREATER_THAN_OR_EQUAL"] = "GREATER_THAN_OR_EQUAL";
    ProfileFieldComparator["IN"] = "IN";
    ProfileFieldComparator["LESS_THAN"] = "LESS_THAN";
    ProfileFieldComparator["LESS_THAN_OR_EQUAL"] = "LESS_THAN_OR_EQUAL";
    ProfileFieldComparator["NOT_IN"] = "NOT_IN";
    ProfileFieldComparator["TRUE"] = "TRUE";
})(ProfileFieldComparator = exports.ProfileFieldComparator || (exports.ProfileFieldComparator = {}));
/**
 * ReviewStatusEnum
 */
var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["CANCELLED"] = "CANCELLED";
    ReviewStatus["COMPLETED"] = "COMPLETED";
    ReviewStatus["EXPIRED"] = "EXPIRED";
    ReviewStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ReviewStatus["IN_REVIEW"] = "IN_REVIEW";
    ReviewStatus["NOT_STARTED"] = "NOT_STARTED";
    ReviewStatus["REJECTED"] = "REJECTED";
})(ReviewStatus = exports.ReviewStatus || (exports.ReviewStatus = {}));
/**
 * SchoolTypeEnum
 */
var SchoolTypeEnum;
(function (SchoolTypeEnum) {
    SchoolTypeEnum["COLLEGES_AND_UNIVERSITIES"] = "COLLEGES_AND_UNIVERSITIES";
    SchoolTypeEnum["COLLEGES_AND_UNIVERSITY_CAMPUSES"] = "COLLEGES_AND_UNIVERSITY_CAMPUSES";
    SchoolTypeEnum["PRIVATE"] = "PRIVATE";
    SchoolTypeEnum["PUBLIC"] = "PUBLIC";
})(SchoolTypeEnum = exports.SchoolTypeEnum || (exports.SchoolTypeEnum = {}));
/**
 * SocialProvider enum
 */
var SocialProvider;
(function (SocialProvider) {
    SocialProvider["INSTAGRAM"] = "INSTAGRAM";
    SocialProvider["OTHER"] = "OTHER";
    SocialProvider["SNAPCHAT"] = "SNAPCHAT";
})(SocialProvider = exports.SocialProvider || (exports.SocialProvider = {}));
/**
 * UserLocationPreferenceEnum
 */
var UserLocationPreference;
(function (UserLocationPreference) {
    UserLocationPreference["KINDA"] = "KINDA";
    UserLocationPreference["NO"] = "NO";
    UserLocationPreference["YES"] = "YES";
})(UserLocationPreference = exports.UserLocationPreference || (exports.UserLocationPreference = {}));
/**
 * UserNotificationPreferenceEnum
 */
var UserNotificationPreference;
(function (UserNotificationPreference) {
    UserNotificationPreference["LIGHT"] = "LIGHT";
    UserNotificationPreference["OFF"] = "OFF";
    UserNotificationPreference["OFTEN"] = "OFTEN";
})(UserNotificationPreference = exports.UserNotificationPreference || (exports.UserNotificationPreference = {}));
/**
 * VerbTypeDesc
 */
var VerbTypeEnum;
(function (VerbTypeEnum) {
    VerbTypeEnum["ANSWER"] = "ANSWER";
    VerbTypeEnum["ENRICH"] = "ENRICH";
    VerbTypeEnum["INSTALL"] = "INSTALL";
    VerbTypeEnum["INTERVIEW"] = "INTERVIEW";
    VerbTypeEnum["REFER"] = "REFER";
    VerbTypeEnum["SHARE"] = "SHARE";
})(VerbTypeEnum = exports.VerbTypeEnum || (exports.VerbTypeEnum = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
