import { Container } from 'use-container'

interface IApp {
  loading: boolean
  error: boolean
  errorMessage: string
  drawer: boolean
  navHidden: boolean
}

export class AppStateContainer extends Container<IApp> {
  state = {
    loading: false,
    error: false,
    errorMessage: '',
    drawer: false,
    navHidden: false,
  }

  setLoading = (loading: boolean): void => this.setState({ loading })
  setError = (message: string): void =>
    this.setState({ error: true, errorMessage: message })

  setDrawer = (drawer: boolean) => this.setState({ drawer })
  setNavHidden = (navHidden: boolean) => this.setState({ navHidden })
}
