export enum OnboardingKeys {
  NAME = 'name',
  PHONE_NUMBER = 'phoneNumber',
  INDUSTRY = 'industry',
  AGENCY_LABEL = 'agency',
  IS_CREATOR_MANAGER = 'isManager',
  TERMS_AGREED = 'termsAgreed',
}

export interface OnboardingData {
  [OnboardingKeys.NAME]: string
  [OnboardingKeys.PHONE_NUMBER]: string
  [OnboardingKeys.INDUSTRY]: string
  [OnboardingKeys.AGENCY_LABEL]: string
  [OnboardingKeys.IS_CREATOR_MANAGER]: boolean
  [OnboardingKeys.TERMS_AGREED]: boolean
}

export interface OnboardingStepProps {
  data: OnboardingData
  onChange: (data: Partial<OnboardingData>) => void
  onNext: (e?: any) => void
  onBack: () => void
  label: string
  isLoading: boolean
  nextButton?: React.ReactElement | null
}
