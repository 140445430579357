import * as React from 'react'
import RetoolEmbed from '../../components/RetoolEmbed'
import useOrganizationCommunityPermission from '../../hooks/useOrganizationCommunityPermission'

const CommunityAudience: React.FC = () => {
  const ocp = useOrganizationCommunityPermission()

  if (!ocp) {
    return null
  }

  const params = {
    community: ocp.community.id,
    communityName: ocp.community.name,
    communityAccessToken: ocp.communityAccessToken,
  }

  // https://zebraiq.retool.com/apps/dashboard/Audience
  return (
    <RetoolEmbed
      src="https://zebraiq.retool.com/embedded/public/feb2c49a-e0f8-4b2f-b46b-8db778dd2775"
      params={params}
    />
  )
}

export default CommunityAudience
