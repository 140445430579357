import React from 'react'
import { useContainer } from 'use-container'
import { ReferralCodeStateContainer } from '../data/referralCode'

const useReferralCode = (callback?: () => void) => {
  const referralCodeCtx = useContainer(ReferralCodeStateContainer)

  const handleListenReferralCode = React.useCallback((): void => {
    const params = new URLSearchParams(window.location.search)
    const referralCodeParam = params.get('referralCode')

    if (referralCodeParam) {
      referralCodeCtx.setReferralCode(referralCodeParam)
      referralCodeCtx.persist()
    }

    if (callback) return callback()
  }, [referralCodeCtx, callback])

  React.useEffect(() => {
    handleListenReferralCode()
  }, [handleListenReferralCode])

  return null
}

export default useReferralCode
