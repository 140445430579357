import config from '../config'
import { track, EventType } from './events'

window.Intercom('onShow', () => track(EventType.SupportWindowOpened))

window.Intercom('onHide', () => track(EventType.SupportWindowClosed))

export const bootIntercom = (
  id: string,
  name: string,
  email: string,
  intercomUserHash: string,
): void => {
  window.Intercom('boot', {
    // eslint-disable-next-line @typescript-eslint/camelcase
    app_id: config.intercom.appId,
    // eslint-disable-next-line @typescript-eslint/camelcase
    user_id: id,
    name,
    email,
    // eslint-disable-next-line @typescript-eslint/camelcase
    user_hash: intercomUserHash,
  })
}

export const openIntercom = (): void => window.Intercom('show')

export const shutdownIntercom = (): void => window.Intercom('shutdown')
