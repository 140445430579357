import styled from 'styled-components'
import {
  alignContent,
  AlignContentProps,
  alignItems,
  AlignItemsProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  borderRadius,
  BorderRadiusProps,
  BoxShadowProps,
  boxShadow,
  display,
  DisplayProps,
  flex,
  FlexProps,
  flexDirection,
  FlexDirectionProps,
  flexGrow,
  FlexGrowProps,
  flexWrap,
  FlexWrapProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system'

export interface BoxProps
  extends AlignContentProps,
    AlignItemsProps,
    BackgroundProps,
    BorderProps,
    BorderRadiusProps,
    BoxShadowProps,
    DisplayProps,
    FlexProps,
    FlexDirectionProps,
    FlexGrowProps,
    FlexWrapProps,
    HeightProps,
    JustifyContentProps,
    MinHeightProps,
    MinWidthProps,
    OverflowProps,
    PositionProps,
    SizeProps,
    SpaceProps,
    WidthProps {
  transform?: string
}

export const Box = styled.div<BoxProps>`
  ${alignContent};
  ${alignItems};
  ${background};
  ${border};
  ${borderRadius};
  ${boxShadow};
  ${display};
  ${flex};
  ${flexDirection};
  ${flexGrow};
  ${flexWrap};
  ${height};
  ${justifyContent};
  ${minHeight};
  ${minWidth};
  ${overflow};
  ${position};
  ${size};
  ${space};
  ${width};

  .fade-enter .btn {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-enter-active .btn {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit .btn {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active .btn {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-enter-active .btn,
  .fade-exit-active .btn {
    transition: opacity 500ms, transform 500ms;
  }
`

export default Box
