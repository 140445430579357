import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { ListOrganizationCommunityPermissionsQuery } from '@zebra-iq/common'
import { DocumentNode } from 'graphql'

interface OrganizationCommunityPermission {
  community: {
    id: string
    name: string
  }
  communityAccessToken: string
}

const useOrganizationCommunityPermission = (): OrganizationCommunityPermission | null => {
  const { id } = useParams()
  const { data } = useQuery(
    ListOrganizationCommunityPermissionsQuery as DocumentNode,
  )

  if (!data) {
    return null
  }

  const ocp = data.listOrganizationCommunityPermissions.find(
    (ocp: any) => ocp.community.id === id,
  )

  if (!ocp) {
    return null
  }

  return ocp
}

export default useOrganizationCommunityPermission
