import logger from './logger'

export enum EventType {
  UserSignedIn = 'User signed in',
  UserSignedOut = 'User signed out',
  SupportWindowOpened = 'Support window opened',
  SupportWindowClosed = 'Support window closed',
  CreateSubscription = 'Create subscription',
  CreateOrganization = 'Create organization',
  SetDefaultPaymentMethod = 'Set default payment method',
  AttachedPaymentMethod = 'Attached payment method',
  UpdatedPaymentMethod = 'Detached payment method',
  RequestChangeEmailAddress = 'Request change email address',
  RequestChangePlan = 'Request change plan',
  PageViewed = 'Page viewed',
  OnboardingScreenViewed = 'Onboarding screen viewed',
  AcceptTerms = 'Accept terms',
}

export function track(ev: EventType, params?: object): void {
  logger.info(ev.toString(), params)
}
