import styled from 'styled-components'
import { mediaQuery } from '../../styles'

export const navBarAnimationDuration = 0.25

export const Container = styled.div<{ marginLeft: string }>`
  height: auto;
  width: 100%;
  margin-left: 0px;
  transition: margin ${navBarAnimationDuration}s;
  overflow-x: hidden;
  overflow-y: auto;

  ${mediaQuery.medium} {
    height: 100vh;
    margin-left: ${({ marginLeft }) => marginLeft || '0px'};
  }
`
