import { useCallback, useEffect } from 'react'
import { useContainer } from 'use-container'
import { AuthStep } from '@zebra-iq/common'
import config from '../../config'
import logger from '../../lib/logger'
import firebase from '../../lib/firebase'
import { SignInStateContainer } from './SignInStateContainer'

function isEmail(email: string): boolean {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const useSignInEmailLink = (
  onToken: Function,
): [string | null, Function, Function] => {
  const signInData = useContainer(SignInStateContainer)

  const handleLoad = useCallback(async () => {
    signInData.restoreEmail()

    if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
      return
    }

    signInData.setStep(AuthStep.SIGN_IN)

    if (!signInData.state.email) {
      signInData.setError(
        'Please open the link on the same device and browser.',
      )
      signInData.setLoading(false)
      return
    }

    signInData.setLoading(true)
    signInData.clearError()

    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailLink(signInData.state.email, window.location.href)

      const idToken = await userCredential.user!.getIdToken()
      signInData.setIdToken(idToken)

      onToken()
    } catch (err) {
      logger.error(err)
      signInData.setError(
        'This link may have expired. Please request a new link.',
      )
      signInData.setLoading(false)
    }
  }, [signInData, onToken])

  useEffect(() => {
    handleLoad() // eslint-disable-line @typescript-eslint/no-floating-promises
  }, [handleLoad])

  const changeEmail = useCallback(
    (email: string) => {
      signInData.setEmail(email)
    },
    [signInData],
  )

  const startSignIn = useCallback(async () => {
    if (!signInData.state.email) {
      signInData.setError('No email provided')
      return
    }

    if (!isEmail(signInData.state.email)) {
      signInData.setError('Invalid email provided')
      return
    }

    signInData.setLoading(true)
    signInData.clearError()
    signInData.persistEmail()

    try {
      await firebase.auth().sendSignInLinkToEmail(signInData.state.email, {
        url: `${config.app.url}/auth`,
        handleCodeInApp: true,
      })

      signInData.setStep(AuthStep.SIGN_IN_CHECK_EMAIL)
    } catch (err) {
      logger.error(err)
      signInData.setError(err)
    } finally {
      signInData.setLoading(false)
    }
  }, [signInData])

  const lastEmail = signInData.state.email

  return [lastEmail, changeEmail, startSignIn]
}

export default useSignInEmailLink
