import styled, { css } from 'styled-components'
import { gradients } from '../styles'

interface Props {
  gradient?: boolean
}

const Tag = styled.div<Props>`
  height: 26px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 6px;

  background: #f3f3f3;

  span {
    color: #878787;
  }

  ${({ gradient }) =>
    gradient &&
    css`
      background: ${gradients.purpleLight};

      span {
        background: ${gradients.purple};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    `}
`

export default Tag
