import * as React from 'react'
import RetoolEmbed from '../../components/RetoolEmbed'

const SlangPage: React.FC = () => {
  // https://zebraiq.retool.com/apps/dashboard/Slang
  return (
    <RetoolEmbed src="https://zebraiq.retool.com/embedded/public/49eea59a-8eda-4d07-918c-582aa1edee8d" />
  )
}

export default SlangPage
