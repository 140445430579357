"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCommunityQueryHappy = {
    getCommunity: {
        __typename: 'Community',
        cardImageURL: 'https://images.unsplash.com/photo-1590949499082-3f2aad1184e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1233&q=80',
        fullWidthIcon: false,
        iconURL: 'https://cdn.iconscout.com/icon/free/png-256/voice-45-470369.png',
        id: '72251560-a62e-437f-860c-42b8e63ab057',
        joinText: 'Join the #BLM community to take a stand for human dignity and respect in the fight against systemic racism, oppression, and police brutality. Justice for George Floyd, Ahmaud Arbery, Tony McDade, Breonna Taylor, and so many other who had their rights taken from them. Join the movement behind Black Lives Matter that has taken to the streets with power, strength, and energy at a colossal scale.',
        logoURL: '',
        name: 'Black Lives Matter',
        primaryColor: '#424042',
    },
};
