import { Container } from 'use-container'
import { AuthStep } from '@zebra-iq/common'
import config from '../../config'

interface ISignIn {
  loading: boolean
  error: boolean
  errorMessage: string
  email: string
  idToken: string
  step: AuthStep | null
}

export const AUTH_ERROR_TIMEOUT = 2000

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  email: '',
  idToken: '',
  step: AuthStep.SIGN_IN_LANDING,
}

export class SignInStateContainer extends Container<ISignIn> {
  state = initialState

  setLoading = (loading: boolean): void => this.setState({ loading })
  setEmail = (email: string): void => this.setState({ email })
  setIdToken = (idToken: string): void => this.setState({ idToken })

  setStep = (step: AuthStep): void => this.setState({ step })
  setError = (err: Error | string): void => {
    this.setState({
      error: Boolean(err),
      errorMessage: err ? err.toString() : '',
    })

    setTimeout(() => {
      this.clearError()
    }, AUTH_ERROR_TIMEOUT)
  }

  clearError = (): void =>
    this.setState({
      error: false,
      errorMessage: '',
    })

  persistEmail = (): void => {
    localStorage.setItem(config.auth.emailKey, this.state.email)
  }

  restoreEmail = (): void => {
    this.setState({ email: localStorage.getItem(config.auth.emailKey) || '' })
  }

  reset = (): void => {
    this.setState(initialState)
  }
}
