import * as React from 'react'
import { useContainer } from 'use-container'
import { useHistory } from 'react-router'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import {
  AttachPaymentMethodMutation,
  SetDefaultPaymentMethodMutation,
  DetachPaymentMethodMutation,
  ListPaymentMethodsQuery,
  Roles,
} from '@zebra-iq/common'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import logger from '../../../../lib/logger'
import Box from '../../../../components/Box'
import {
  Heading3,
  Span,
  Heading4,
  Label,
} from '../../../../components/Typography'
import Button from '../../../../components/Button'
import { colors, variables, gradients } from '../../../../styles'
import { TextInput } from '../../../../components/Input'
import CreditCard from '../../../../components/Input/components/CreditCard'
import Card from '../../../../components/Card'
import LightBulb from '../../../../components/Svg/LightBulb'
import { AuthStateContainer } from '../../../../data/auth'
import { openIntercom } from '../../../../lib/intercom'
import { track, EventType } from '../../../../lib/events'
import { useAuth } from '../../../../hooks/useAuth'
import { Section, SectionHeader } from './styled'

const Landing: React.FC = () => {
  const authData = useContainer(AuthStateContainer)
  const isOrgAdmin = authData.state.roles.includes(Roles.ORG_ADMIN)

  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const { refreshToken } = useAuth()

  const [attachPaymentMethod] = useMutation(
    AttachPaymentMethodMutation as DocumentNode,
  )
  const [setDefaultPaymentMethod] = useMutation(
    SetDefaultPaymentMethodMutation as DocumentNode,
  )
  const [detachPaymentMethod] = useMutation(
    DetachPaymentMethodMutation as DocumentNode,
  )
  const { data, refetch } = useQuery(ListPaymentMethodsQuery as DocumentNode, {
    skip: !isOrgAdmin,
  })

  const handleSignout = () => {
    authData.reset()
    history.push('/auth')
  }

  const handleUpdatePaymentMethod = async () => {
    const cardElement = elements!.getElement(CardElement)

    const { error, paymentMethod } = await stripe!.createPaymentMethod({
      card: cardElement!,
      type: 'card',
      metadata: {
        userID: authData.state.user.id,
      },
    })

    if (error || !paymentMethod) {
      logger.error({
        name: 'Stripe error',
        message: 'A stripe error',
        ...error,
      })
      return
    }

    await refreshToken()

    await attachPaymentMethod({
      variables: {
        input: {
          paymentMethodToken: paymentMethod.id,
        },
      },
    })

    await setDefaultPaymentMethod({
      variables: {
        input: {
          paymentMethodToken: paymentMethod.id,
        },
      },
    })

    if (data.listPaymentMethods.length === 0) {
      return
    }

    const currentDefaultPaymentMethod = data.listPaymentMethods.find(
      (payment: any) => payment.default,
    )

    if (!currentDefaultPaymentMethod) {
      refetch()
      return
    }

    await detachPaymentMethod({
      variables: {
        input: {
          paymentMethodToken: currentDefaultPaymentMethod.id,
        },
      },
    })

    track(EventType.UpdatedPaymentMethod)
  }

  if (!authData.state.user) {
    return null
  }

  const { email } = authData.state.user

  return (
    <Box px={48} py={40}>
      <Heading3 as="h1">Settings</Heading3>
      <Span>
        Modify information or update options to keep your Zebra experience
        up-to-date.
      </Span>

      <Section>
        <SectionHeader>
          <Heading4>Email</Heading4>
          <Span>{`Your email is currently set to ${email}.`}</Span>
        </SectionHeader>
        <Box mt="12px" display="flex">
          <Button
            background={gradients.orange}
            color={colors.white}
            onClick={() => {
              openIntercom()
              track(EventType.RequestChangeEmailAddress)
            }}
          >
            Change address →
          </Button>
        </Box>
      </Section>

      {isOrgAdmin && (
        <>
          {/* <Section>
            <SectionHeader>
              <Heading4>Organization</Heading4>
              <Span>You currently have 5 team members in your organization.</Span>
            </SectionHeader>
            <Box mt="12px" display="flex">
              <Button variant="gray" mr={16}>
                Remove member →
              </Button>
              <Button>Invite another →</Button>
            </Box>
          </Section>*/}
          <Section>
            <SectionHeader>
              <Heading4>Plan</Heading4>
              <Span>You are currently on the Premium plan.</Span>
            </SectionHeader>
            <Box mt="12px" display="flex">
              <Button
                variant="gray"
                onClick={() => {
                  openIntercom()
                  track(EventType.RequestChangePlan)
                }}
              >
                Change plan →
              </Button>
            </Box>
          </Section>

          <Section>
            <SectionHeader>
              <Heading4>Payment Method</Heading4>
              <Span>
                Update your credit card billing information below if it has
                changed recently.
              </Span>
            </SectionHeader>
            <Box mt="12px" display="flex">
              <Card mt={12} width={548} ml={12} p={16}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <Box width={232}>
                    <Label mb={12}>Name</Label>
                    <TextInput mb={16} placeholder="First & last please" />
                  </Box>

                  <Box width={232} height={80} display="flex">
                    <Box
                      display="flex"
                      alignItems="center"
                      width={1}
                      background="#F1F1F1"
                      borderRadius={variables.borderRadius}
                      height={32}
                      mt="auto"
                      mb={16}
                      px="8px"
                    >
                      <LightBulb />
                      <Span ml="8px" color="#838383">
                        We need your
                        <em> billing </em>
                        address.
                      </Span>
                    </Box>
                  </Box>

                  <Box width={232}>
                    <Label mb={12}>Address</Label>
                    <TextInput mb={16} placeholder="1500 Pine Street" />
                  </Box>

                  <Box width={232}>
                    <Label mb={12}>City</Label>
                    <TextInput mb={16} placeholder="San Francisco" />
                  </Box>

                  <Box width={232}>
                    <Label mb={12}>Address (Line 2)</Label>
                    <TextInput mb={16} placeholder="#800" />
                  </Box>

                  <Box width={232}>
                    <Label mb={12}>State</Label>
                    <TextInput mb={16} placeholder="California" />
                  </Box>
                </Box>

                <Label mb={12}>Credit Card</Label>
                <CreditCard />
              </Card>
            </Box>
            <Button
              width={65}
              mt={16}
              ml={12}
              onClick={handleUpdatePaymentMethod}
            >
              Save
            </Button>
          </Section>
        </>
      )}

      <Section>
        <SectionHeader>
          <Heading4>Sign out</Heading4>
          <Span>
            If you share a computer, or otherwise want to prevent unauthorized
            access, please sign out.
          </Span>
        </SectionHeader>
        <Box mt="12px" display="flex">
          <Button
            background={gradients.red}
            color={colors.white}
            onClick={handleSignout}
          >
            Proceed →
          </Button>
        </Box>
      </Section>
    </Box>
  )
}

export default Landing
