/* eslint-disable no-underscore-dangle */

declare global {
  interface Window {
    _env_: {
      APP_URL: string
      API_URL: string
      NODE_ENV: string
      FIREBASE_API_KEY: string
      FIREBASE_AUTH_DOMAIN: string
      FIREBASE_DATABASE_URL: string
      FIREBASE_PROJECT_ID: string
      FIREBASE_STORAGE_BUCKET: string
      FIREBASE_MESSAGING_SENDER_ID: string
      FIREBASE_APP_ID: string
      STRIPE_PUBLIC_KEY: string
      INTERCOM_APP_ID: string
      ENABLE_JAM: string
    }
  }
}

export default {
  env: window._env_.NODE_ENV,
  api: {
    url: window._env_.API_URL || 'http://localhost:4000/graphql',
  },
  app: {
    url: window._env_.APP_URL,
  },
  firebase: {
    apiKey: window._env_.FIREBASE_API_KEY,
    authDomain: window._env_.FIREBASE_AUTH_DOMAIN,
    databaseURL: window._env_.FIREBASE_DATABASE_URL,
    projectId: window._env_.FIREBASE_PROJECT_ID,
    storageBucket: window._env_.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window._env_.FIREBASE_MESSAGING_SENDER_ID,
    appId: window._env_.FIREBASE_APP_ID,
  },
  stripe: {
    key: window._env_.STRIPE_PUBLIC_KEY,
  },
  auth: {
    emailKey: 'sign-in-email',
  },
  intercom: {
    appId: window._env_.INTERCOM_APP_ID,
  },
  sentry: {
    dsn:
      'https://66cf49bbdec04912a6f72b976fcbfdf9@o345230.ingest.sentry.io/5203081',
  },
  jam: {
    enable: window._env_.ENABLE_JAM === 'true',
    projectId: 'angry-badger-6055',
  },
  links: {
    termsOfService: 'https://zebraiq.com/terms',
    contact: 'mailto:friends@zebraiq.com',
  },
}
