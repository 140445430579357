import { useCallback } from 'react'
import { useContainer } from 'use-container'
import firebase from '../../lib/firebase'
import logger from '../../lib/logger'
import { SignInStateContainer } from './SignInStateContainer'

const useSignInGoogle = (onToken: Function): (() => Promise<void>) => {
  const signInData = useContainer(SignInStateContainer)

  return useCallback(async (): Promise<void> => {
    const provider = new firebase.auth.GoogleAuthProvider()

    try {
      signInData.setLoading(true)

      const userCredential = await firebase.auth().signInWithPopup(provider)

      const idToken = await userCredential.user!.getIdToken()
      const email = userCredential.user!.email || ''

      signInData.setIdToken(idToken)
      signInData.setEmail(email)
      signInData.clearError()

      onToken()
    } catch (err) {
      logger.error(err)
      signInData.setError(err)
      signInData.setLoading(false)
    }
  }, [signInData, onToken])
}

export default useSignInGoogle
