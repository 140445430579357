import { Container } from 'use-container'

interface IReferralCode {
  referralCode: string
}

export const REFERRAL_CODE_IDENTIFIER = 'referral-code'

export class ReferralCodeStateContainer extends Container<IReferralCode> {
  constructor() {
    super()

    this.state = {
      referralCode: localStorage.getItem(REFERRAL_CODE_IDENTIFIER) || '',
    }
  }

  setReferralCode = (referralCode: string) => this.setState({ referralCode })

  removeReferralCode = () => {
    localStorage.removeItem(REFERRAL_CODE_IDENTIFIER)
    this.setState({ referralCode: '' })
  }

  persist = (): void => {
    localStorage.setItem(
      REFERRAL_CODE_IDENTIFIER,
      this.state.referralCode || '',
    )
  }

  reset = (): void => {
    localStorage.removeItem(REFERRAL_CODE_IDENTIFIER)

    this.setState({
      referralCode: '',
    })
  }
}
