import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { RetryLink } from 'apollo-link-retry'
import { onError, ErrorResponse } from 'apollo-link-error'
import {
  ApolloLink,
  Observable,
  FetchResult,
  fromPromise,
  Operation,
} from 'apollo-link'
import { getContainer } from 'use-container'
import config from '../../config'
import logger from '../../lib/logger'
import { AuthStateContainer } from '../auth'
import { refreshToken } from './utils'

const UNAUTHENTICATED_OPERATIONS = ['SignInRefreshToken', 'SignInIdToken']

const httpLink = createHttpLink({
  uri: config.api.url,
})

const authLink = setContext(({ operationName }, { headers }) => {
  const authData = getContainer(AuthStateContainer)

  let token: string | null | undefined = null
  if (!UNAUTHENTICATED_OPERATIONS.includes(operationName || '')) {
    token = authData.state.accessToken
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Device-Type': 'WEB',
    },
  }
})

let refreshTokenPromise: Promise<string | null> | null = null

const errorLink = onError(
  ({
    forward,
    graphQLErrors,
    networkError,
    operation,
  }: ErrorResponse): Observable<FetchResult> | void => {
    let hasAuthError = false
    const { operationName } = operation

    // log errors
    if (graphQLErrors && graphQLErrors.length) {
      for (const graphQLErr of graphQLErrors) {
        const { extensions, locations, path } = graphQLErr

        if (extensions) {
          if (extensions.code === 'E_AUTHENTICATION') {
            hasAuthError = true
            continue
          }

          if (extensions.code === 'E_EXPIRED') {
            // dont send to sentry
            continue
          }
        }

        const err = new Error(
          `GraphQL Error ${operationName}: ${graphQLErr.message}`,
        )

        logger.error(err, {
          operationName,
          locations,
          path,
          extensions,
        })
      }
    } else if (networkError) {
      let err: Error = networkError
      const rawErr = networkError as any

      if (
        rawErr.name === 'TypeError' &&
        rawErr.message &&
        rawErr.message.includes('Network request failed')
      ) {
        // eslint-disable-next-line no-console
        console.error(err)
        return
      }

      if (rawErr.statusCode) {
        const bodyText = rawErr.bodyText || JSON.stringify(rawErr.result)
        const errMessage = `GraphQL Network Error ${operationName} (${rawErr.statusCode}): ${bodyText}`
        err = new Error(errMessage)
      }

      logger.error(err, {
        operationName,
      })
    }

    // refresh access token
    if (hasAuthError && !UNAUTHENTICATED_OPERATIONS.includes(operationName)) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken(client)
          .then((token: string | null): string | null => {
            refreshTokenPromise = null

            if (token) {
              logger.info('Refreshed access token')
            } else {
              logger.error(
                new Error(
                  `Attempted access token refresh, invalid refresh token`,
                ),
              )
            }
            return token
          })
          .catch((err: Error) => {
            refreshTokenPromise = null
            logger.error(err, {
              originalOperationName: operationName,
            })

            return null
          })
      }

      const refreshObservable = fromPromise(
        refreshTokenPromise.then((token: string | null): boolean => {
          if (!token) {
            // invalid refresh token, propagate original error
            throw networkError
          }

          const oldHeaders = operation.getContext().headers
          const authorization = `Bearer ${token}`

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization,
            },
          })

          return true
        }),
      )

      return refreshObservable.flatMap(() => forward(operation))
    }
  },
)

// retry logic
const retryIf = (error: any, operation: Operation) => {
  if (!error) {
    return false
  }

  const doNotRetryCodes: number[] = [500, 400]
  const shouldRetryOnStatusCode: boolean = error.statusCode
    ? !doNotRetryCodes.includes(error.statusCode)
    : true

  const isQuery = Boolean(
    operation.query.definitions.find(
      (d: any) =>
        d.name.value === operation.operationName && d.operation === 'query',
    ),
  )

  return Boolean(error) && isQuery && shouldRetryOnStatusCode
}

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf,
  },
})

const client = new ApolloClient({
  name: 'ZebraDashboard',
  version: process.env.REACT_APP_CLIENT_VERSION || 'local',
  link: ApolloLink.from([retryLink, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
