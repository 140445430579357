import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useContainer } from 'use-container'
import { DocumentNode } from 'graphql'
import { ListOrganizationCommunityPermissionsQuery } from '@zebra-iq/common'
import { AuthStateContainer } from '../data/auth'
import RetoolEmbed from '../components/RetoolEmbed'

const Overview: React.FC = () => {
  const authData = useContainer(AuthStateContainer)
  const orgCreated =
    authData.state.user?.organizationMemberships?.length > 0 || false

  const { data } = useQuery(
    ListOrganizationCommunityPermissionsQuery as DocumentNode,
    {
      skip: !orgCreated,
    },
  )

  if (!data) {
    return null
  }

  const ocpStr = JSON.stringify(data.listOrganizationCommunityPermissions)

  const params = { organizationCommunityPermissions: ocpStr }

  // https://zebraiq.retool.com/apps/dashboard/Overview
  return (
    <RetoolEmbed
      src="https://zebraiq.retool.com/embedded/public/eeb931f8-ecbd-45b7-97d6-a8d28a3e6a7e"
      params={params}
    />
  )
}

export default Overview
