import * as React from 'react'
import RetoolEmbed from '../../components/RetoolEmbed'
import useOrganizationCommunityPermission from '../../hooks/useOrganizationCommunityPermission'

const CommunityResponses: React.FC = () => {
  const ocp = useOrganizationCommunityPermission()

  if (!ocp) {
    return null
  }

  const params = {
    community: ocp.community.id,
    communityName: ocp.community.name,
    communityAccessToken: ocp.communityAccessToken,
  }

  // https://zebraiq.retool.com/apps/dashboard/Responses
  return (
    <RetoolEmbed
      src="https://zebraiq.retool.com/embedded/public/20706fc5-4e5d-4e03-b920-e00fcb423a5b"
      params={params}
    />
  )
}

export default CommunityResponses
