import React from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { useHistory, withRouter } from 'react-router'
import styled from 'styled-components'
import { useContainer } from 'use-container'
import { AuthStep, SignInIdTokenMutation } from '@zebra-iq/common'
import { useMutation } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import JwtDecode from 'jwt-decode'
import apolloClient from '../../data/apollo/client'
import Box from '../../components/Box'
import LayoutGradient from '../../layout/LayoutGradient'
import { mediaQuery } from '../../styles'
import { SignInStateContainer } from '../../components/SignIn/SignInStateContainer'
import useSignInEmailLink from '../../components/SignIn/useSignInEmailLink'
import { AuthStateContainer } from '../../data/auth'
import { AppStateContainer } from '../../data/app'
import useGetOrganizationApplication from '../../hooks/useGetOrganizationApplication'
import { trackEvent } from '../../lib/analytics'
import { getApolloErrorMesage } from '../../lib/errors'
import HeaderNavigation from '../../components/HeaderNavigation'
import AuthEmailConfirm from './AuthEmailConfirm'
import AuthSignIn from './AuthSignIn'
import AuthLanding from './AuthLanding'

const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  ${mediaQuery.tablet} {
    height: 100%;
    justify-content: center;
  }
`

const STEPS_KEY = {
  [AuthStep.SIGN_IN_LANDING]: {
    Component: AuthLanding,
  },
  [AuthStep.SIGN_IN]: {
    Component: AuthSignIn,
  },
  [AuthStep.SIGN_IN_CHECK_EMAIL]: {
    Component: AuthEmailConfirm,
  },
}

const AuthPage: React.FC<{ location: any }> = () => {
  const history = useHistory()
  const signInData = useContainer(SignInStateContainer)
  const authData = useContainer(AuthStateContainer)
  const appData = useContainer(AppStateContainer)

  const [direction, setDirection] = React.useState<'next' | 'back' | null>(null)
  const currentStep = signInData.state.step

  const handleOnNext = React.useCallback((): void => {
    trackEvent('Pressing next on /auth')

    // clear any errors
    signInData.clearError()

    if (currentStep === AuthStep.SIGN_IN_LANDING) {
      signInData.setStep(AuthStep.SIGN_IN)
    } else if (currentStep === AuthStep.SIGN_IN) {
      history.push('/onboarding')
    }

    setDirection('next')
  }, [currentStep, signInData, history])

  const handleOnBack = React.useCallback((): void => {
    trackEvent('Pressing back on /auth')

    // clear any errors
    signInData.setLoading(false)
    signInData.clearError()

    if (currentStep === AuthStep.SIGN_IN_CHECK_EMAIL) {
      signInData.setStep(AuthStep.SIGN_IN)
    } else {
      signInData.setStep(AuthStep.SIGN_IN_LANDING)
    }
    setDirection('back')
  }, [currentStep, signInData])

  const [getOrganization] = useGetOrganizationApplication({
    onCompleted: data => {
      // if organization application active
      if (data && data.id) {
        history.push('/waitlist')
      } else {
        // if no organization application, is new user
        handleOnNext()
      }

      signInData.setLoading(false)
    },
    onError: () => {
      signInData.setLoading(false)
      handleOnNext()
    },
  })

  const [signIn] = useMutation(SignInIdTokenMutation as DocumentNode, {
    async onCompleted({ signInIdToken }) {
      if (!signInIdToken) {
        return
      }

      appData.setNavHidden(false)

      const decoded: any = JwtDecode(signInIdToken.accessToken)

      authData.login(signInIdToken)
      authData.setRoles(decoded.roles)
      authData.persist()

      const { organizationMemberships } = signInIdToken.user

      await apolloClient.resetStore()

      // if user is not on waitlist
      if (
        organizationMemberships.length &&
        signInIdToken.user.acceptedTerms &&
        signInIdToken.user.isActive
      ) {
        signInData.setLoading(false)
        history.push('/')
      } else {
        // if user is new or on waitlist, verify by getting organization
        getOrganization()
      }
    },
    onError(err) {
      const parsedError = getApolloErrorMesage(err)

      signInData.setError(parsedError)
      signInData.setLoading(false)
    },
  })

  const handleSignIn = React.useCallback(async () => {
    trackEvent('Signing in user')
    await signIn({
      variables: {
        input: {
          idToken: signInData.state.idToken,
        },
      },
    })
  }, [signIn, signInData])

  useSignInEmailLink(handleSignIn)

  const { Component } = STEPS_KEY[currentStep]

  const isFirstStep = currentStep === AuthStep.SIGN_IN_LANDING

  return (
    <LayoutGradient>
      <HeaderNavigation
        onBack={handleOnBack}
        isBackVisible={!isFirstStep}
        footer
      >
        <Box
          height="100%"
          width="100%"
          className={
            direction === 'next' ? 'direction-left' : 'direction-right'
          }
        >
          <SwitchTransition>
            <CSSTransition
              key={currentStep}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
              }}
              classNames="fade"
            >
              <WrapperInner>
                <Component
                  onNext={handleOnNext}
                  onBack={handleOnBack}
                  onAuth={handleSignIn}
                />
              </WrapperInner>
            </CSSTransition>
          </SwitchTransition>
        </Box>
      </HeaderNavigation>
    </LayoutGradient>
  )
}

export default withRouter(AuthPage)
