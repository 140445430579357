import React from 'react'
import styled from 'styled-components'
import PhoneInput from '../../../../components/PhoneInput'
import { InputStyle } from '../styled'
import { FormInput } from '.'

const Wrapper = styled.div`
  width: 100%;

  #phone-input {
    width: 100%;
    ${InputStyle}
  }
`

const OnboardingPhoneInput: React.SFC<FormInput> = ({
  onChange,
  label,
  value,
  required,
}): React.ReactElement => {
  const [placeholderNumber, setPlaceholderNumber] = React.useState<string>(
    value,
  )

  const handleOnChange = ({
    value,
    number,
    isValid,
  }: {
    value: string
    number: string
    isValid: boolean
  }) => {
    setPlaceholderNumber(value)

    if (isValid) {
      onChange({ [label]: number })
      // not proper phone format, use as placeholder but clear
    } else if (!isValid && value) {
      onChange({ [label]: '' })
    }
  }

  return (
    <Wrapper>
      <PhoneInput
        required={required}
        value={placeholderNumber}
        onChange={handleOnChange}
      />
    </Wrapper>
  )
}

export default OnboardingPhoneInput
