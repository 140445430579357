import * as React from 'react'

const LightBulb = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9.01147 13.8157C11.6865 13.8157 13.855 11.6638 13.855 9.00927C13.855 6.35474 11.6865 4.20282 9.01147 4.20282C6.33648 4.20282 4.16797 6.35474 4.16797 9.00927C4.16797 11.6638 6.33648 13.8157 9.01147 13.8157Z"
      fill="#E4B251"
    />
    <path
      d="M7.18555 15.9842C7.34324 16.4089 7.70369 16.7219 8.15425 16.8113V17.0349C8.15425 17.482 8.53722 17.8621 8.98778 17.8621C9.43834 17.8621 9.82132 17.482 9.82132 17.0349V16.8113C10.2719 16.7443 10.6549 16.4089 10.79 15.9842H7.18555Z"
      fill="#E4B251"
    />
    <path
      d="M10.8813 14.7546H7.11914V15.5818H10.8813V14.7546Z"
      fill="#E4B251"
    />
    <path
      d="M10.8813 13.4133H7.11914V14.2404H10.8813V13.4133Z"
      fill="#E4B251"
    />
    <path
      d="M0.428033 8.51746C0.202752 8.51746 0 8.6963 0 8.94221C0 9.18811 0.180224 9.36694 0.428033 9.36694H2.52314C2.74842 9.36694 2.95118 9.18811 2.95118 8.94221C2.95118 8.71866 2.77095 8.51746 2.52314 8.51746H0.428033Z"
      fill="#E4B251"
    />
    <path
      d="M2.92774 2.50378C2.8151 2.50378 2.72499 2.5485 2.63487 2.63793C2.47718 2.79442 2.47718 3.06268 2.63487 3.21918L4.12174 4.69467C4.27943 4.85117 4.54977 4.85117 4.70747 4.69467C4.86517 4.53818 4.86517 4.2699 4.70747 4.11341L3.22061 2.63793C3.15302 2.5485 3.04038 2.50378 2.92774 2.50378Z"
      fill="#E4B251"
    />
    <path
      d="M9.01103 0C8.78576 0 8.58301 0.178837 8.58301 0.42475V2.50383C8.58301 2.72738 8.76323 2.92859 9.01103 2.92859C9.23631 2.92859 9.43905 2.74974 9.43905 2.50383V0.42475C9.41653 0.178837 9.23631 0 9.01103 0Z"
      fill="#E4B251"
    />
    <path
      d="M15.0718 2.50378C14.9592 2.50378 14.8691 2.5485 14.779 2.63793L13.2921 4.11341C13.1344 4.2699 13.1344 4.53818 13.2921 4.69467C13.4498 4.85117 13.7201 4.85117 13.8778 4.69467L15.3647 3.21918C15.5224 3.06268 15.5224 2.79442 15.3647 2.63793C15.2746 2.5485 15.1845 2.50378 15.0718 2.50378Z"
      fill="#E4B251"
    />
    <path
      d="M15.4993 8.51746C15.274 8.51746 15.0713 8.6963 15.0713 8.94221C15.0713 9.16576 15.2515 9.36694 15.4993 9.36694H17.5719C17.7972 9.36694 17.9999 9.18811 17.9999 8.94221C17.9999 8.6963 17.8197 8.51746 17.5719 8.51746H15.4993Z"
      fill="#E4B251"
    />
  </svg>
)

export default LightBulb
