import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'

interface Props {
  isVisible: boolean
  label?: string | React.ReactElement
  opaque?: boolean
  left?: string
}

const HOVER_BG_COLOR = 'rgba(255, 255, 255, 0.2)'

const HOVER_CONTAINER_HEIGHT = 70

export const SPEECH_BUBBLE_WIDTH = 284
const HOVER_CONTAINER_PADDING_Y = 12
const POINTER_HEIGHT = 15

const Wrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${({ isVisible }) => (isVisible ? 1 : -10)};
`

const Container = styled.div<{
  isVisible: boolean
  opaque: boolean
  left?: string
}>`
  background: ${({ opaque }) => (opaque ? colors.white : HOVER_BG_COLOR)};
  border-radius: ${HOVER_CONTAINER_PADDING_Y}px;
  padding: 8px 20px;
  min-width: ${SPEECH_BUBBLE_WIDTH}px;
  top: -${HOVER_CONTAINER_HEIGHT + POINTER_HEIGHT + 5}px;
  left: ${({ left }) => left || '0px'};
  min-height: ${HOVER_CONTAINER_HEIGHT}px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  position: relative;
  width: fit-content;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: ${({ opaque }) => (opaque ? colors.white : HOVER_BG_COLOR)}
      transparent;
    border-width: ${POINTER_HEIGHT}px ${POINTER_HEIGHT}px 0;
    bottom: -${POINTER_HEIGHT}px;
    left: 50%;
    margin-left: -10px;
  }
`

const Text = styled.p<{ opaque: boolean }>`
  color: ${({ opaque }) => (opaque ? colors.gray : 'inherit')};
  text-align: center;
`

const SpeechBubble: React.SFC<Props> = ({
  isVisible,
  label,
  opaque = false,
  left,
}): React.ReactElement => {
  return (
    <Wrapper isVisible={isVisible}>
      <Container
        isVisible={Boolean(isVisible && label)}
        opaque={opaque}
        left={left}
      >
        <Text opaque={opaque}>{label}</Text>
      </Container>
    </Wrapper>
  )
}

export default SpeechBubble
