import React from 'react'
import styled from 'styled-components'
import { colors, mediaQuery } from '../styles'

const Container = styled.div<{ isVisible?: boolean }>`
  min-height: 35px;
  margin: 22px 0px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.2s;
  width: 100%;
  max-width: 100%;
`

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 32px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Label = styled.p`
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  margin: 0;
  padding: 8px 14px;

  ${mediaQuery.tablet} {
    font-size: 18px;
  }
`

interface Props {
  isVisible: boolean
  label: string
}

const ErrorBubble: React.SFC<Props> = ({
  isVisible = false,
  label = '',
}): React.ReactElement => {
  return (
    <Container isVisible={isVisible}>
      <Wrapper>
        <Label>{label}</Label>
      </Wrapper>
    </Container>
  )
}

export default ErrorBubble
