import * as React from 'react'
import RetoolEmbed from '../../components/RetoolEmbed'
import useOrganizationCommunityPermission from '../../hooks/useOrganizationCommunityPermission'

const CommunityCampaigns: React.FC = () => {
  const ocp = useOrganizationCommunityPermission()

  if (!ocp) {
    return null
  }

  const params = {
    community: ocp.community.id,
    communityName: ocp.community.name,
    communityAccessToken: ocp.communityAccessToken,
  }

  // https://zebraiq.retool.com/apps/dashboard/Campaigns
  return (
    <RetoolEmbed
      src="https://zebraiq.retool.com/embedded/public/4f6b442d-e985-4df0-909b-cf5053380164"
      params={params}
    />
  )
}

export default CommunityCampaigns
