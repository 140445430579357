import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ListSubscriptionsQuery } from '@zebra-iq/common'
import { DocumentNode } from 'graphql'
import { useHistory } from 'react-router'
import PlansRadio from '../../../../components/PlansRadio'
import Box from '../../../../components/Box'
import { Heading3, Span } from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'

const Tiers = () => {
  const history = useHistory()
  const [currentTier, setTier] = React.useState('')
  const { data, loading } = useQuery(ListSubscriptionsQuery as DocumentNode)

  React.useEffect(() => {
    if (((data && data.listSubscriptions?.length) || 0) > 0) {
      setTier(data.listSubscriptions[0].plan.id)
    }
  }, [data, setTier])

  if (loading) {
    return <Loader />
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <Box>
          <Heading3 as="h1">Change your plan.</Heading3>
          <Span>You can change back to your old plan within 30 days.</Span>
        </Box>
        <Box mt={24} display="flex" alignItems="center" justifyContent="center">
          <PlansRadio value={currentTier} onChange={setTier} />
        </Box>
        <Box mt={24} display="flex">
          <Button mr="8px" variant="gray" onClick={history.goBack}>
            Back
          </Button>
          <Button>Save</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Tiers
