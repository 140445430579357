import React from 'react'
import styled from 'styled-components'
import Box from '../../../../components/Box'
import { InputStyle } from '../styled'
import { colors } from '../../../../styles'
import { FormInput } from '.'

const INPUT_SIZE = '32px'

export const FormInputCheckboxLabel = styled.span`
  font-size: 16px;

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const Label = styled.label`
  padding-left: 10px;
`

export const Checkbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: ${INPUT_SIZE};

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    ${InputStyle}
    border-radius: 8px;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: ${INPUT_SIZE};
    width: ${INPUT_SIZE};
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    opacity: 0.7;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 13px;
    top: 6px;
    width: 5px;
    height: 16px;
    border: solid ${colors.white};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const OnboardingCheckbox: React.SFC<{
  children: any
} & FormInput> = ({ children, label, value, onChange }): React.ReactElement => {
  const handleOnChange = (): void => {
    onChange({ [label]: !value })
  }

  return (
    <Box display="flex" alignItems="center" marginBottom="16px">
      <Checkbox>
        <input
          type="checkbox"
          id={label}
          name={label}
          checked={value}
          onChange={handleOnChange}
        />
        <span className="checkmark" />
      </Checkbox>
      <Label htmlFor={label}>{children}</Label>
    </Box>
  )
}

export default OnboardingCheckbox
