import { useMutation } from '@apollo/react-hooks'
import { useCallback } from 'react'
import { SignInRefreshTokenMutation } from '@zebra-iq/common'
import jwtDecode from 'jwt-decode'
import { useContainer } from 'use-container'
import { DocumentNode } from 'graphql'
import { AuthStateContainer } from '../data/auth'

export const useAuth = () => {
  const authData = useContainer(AuthStateContainer)
  const [signInRefreshToken] = useMutation(
    SignInRefreshTokenMutation as DocumentNode,
    {
      onCompleted(data) {
        if (!data.signInRefreshToken) {
          return
        }

        authData.setRefreshToken(data.signInRefreshToken.refreshToken)
        authData.setAccessToken(data.signInRefreshToken.accessToken)
        authData.setUser(data.signInRefreshToken.user)

        const decoded: any = jwtDecode(data.signInRefreshToken.accessToken)
        authData.setRoles(decoded.roles)

        authData.persist()
      },
    },
  )

  const refreshToken = useCallback(async () => {
    if (authData.state.refreshToken) {
      await signInRefreshToken({
        variables: {
          refreshToken: authData.state.refreshToken,
        },
      })
    }
  }, [signInRefreshToken, authData.state.refreshToken])

  return {
    refreshToken,
  }
}
