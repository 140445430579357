"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const answerInput = {
    questionID: '123',
    assignmentID: '456',
    answer: {
        answerType: 'TEXT',
        text: 'Strawberries',
        url: '',
    },
};
exports.AnswerQuestionMutationMockHappy = {
    answerQuestion: {
        __typename: 'AnswerQuestionResult',
        answer: {
            __typename: 'Answer',
            id: '06b9080d-6092-47ad-b956-547af5adfda3',
        },
        assignment: {
            __typename: 'Assignment',
            finishedAt: null,
            id: '30a2ea76-a7f8-4d96-86e6-2b0e3a94463c',
            nextQuestion: {
                __typename: 'Question',
                allowAnswerType: ['OPTION', 'TEXT'],
                allowMultipleAnswers: true,
                id: 'bab0f43f-c643-4ca8-a6b0-873a0bfc4bfc',
                options: [
                    {
                        __typename: 'Option',
                        text: 'Strawberries',
                        url: '',
                    },
                    {
                        __typename: 'Option',
                        text: 'Blueberries',
                        url: '',
                    },
                    {
                        __typename: 'Option',
                        text: 'Mango',
                        url: '',
                    },
                ],
                prompt: 'What is your favorite fruit?',
            },
        },
    },
    variables: Object.assign({}, answerInput),
};
exports.AnswerQuestionMutationMockLastQuestion = {
    listAssignments: [
        {
            __typename: 'Assignment',
            assignedAt: '2019-09-26T09:26:52.139Z',
            finishedAt: '2019-09-26T02:30:05.644Z',
            id: '30a2ea76-a7f8-4d96-86e6-2b0e3a94463c',
            nextQuestion: null,
            survey: {
                __typename: 'Survey',
                id: '2a3ee6cc-2f1f-45f0-82e4-d126a3e7ead7',
                name: 'What is your favorite animal?',
                rewardPoints: 10,
            },
        },
    ],
    variables: Object.assign({}, answerInput),
};
