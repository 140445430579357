import React from 'react'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import { DEFAULT_COUNTRY } from '../lib/constants'
import { formValidations } from '../lib/form'

export interface PhoneInputProps {
  value: string
  isValid: boolean
  number: string
  countryCode: string | null
}

const PhoneInput: React.FC<{
  value: string
  onChange: ({ value, isValid, number }: PhoneInputProps) => void
  required?: boolean
}> = ({ value, onChange, required = true, ...rest }): React.ReactElement => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target

    const parseNoCountryCode = parsePhoneNumberFromString(inputValue)
    const parseWithCountryCode = parsePhoneNumberFromString(
      inputValue,
      DEFAULT_COUNTRY.COUNTRY_CODE as CountryCode,
    )

    const parsed = parseNoCountryCode || parseWithCountryCode

    onChange({
      value: parsed ? parsed.formatInternational() : inputValue,
      isValid: parsed?.isValid() || false,
      number: parsed ? parsed.number.toString() : inputValue,
      countryCode: parsed ? (parsed.country as string) : null,
    })
  }

  return (
    <input
      id="phone-input"
      name="phone"
      type="tel"
      value={value || DEFAULT_COUNTRY.COUNTRY_CODE_NUMBER_PREFIX}
      onChange={handleOnChange}
      required={required}
      {...formValidations.phoneNumber}
      {...rest}
    />
  )
}

export default PhoneInput
