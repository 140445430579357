import React from 'react'
import { useContainer } from 'use-container'
import { useLocation } from 'react-router'
import { AppStateContainer } from '../../data/app'
import { publicRoutesStringList, fullScreenRoutesList } from '../../routes'
import AuthenticatedBar from './AuthenticatedBar'
import {
  authenticatedBarWidth,
  publicBarWidth,
} from './AuthenticatedBar/styled'
import { Container } from './styled'

const NavBar: React.SFC = ({ children }): React.ReactElement => {
  const appData = useContainer(AppStateContainer)
  const location = useLocation()

  const isOnFullScreenRoute = fullScreenRoutesList.includes(location.pathname)

  const isOnPublicRoute = publicRoutesStringList.includes(location.pathname)
  const showPublicNavbar = isOnPublicRoute || appData.state.navHidden

  const widthOffset = React.useCallback((): string => {
    if (isOnFullScreenRoute) return '0'
    return showPublicNavbar ? publicBarWidth : authenticatedBarWidth
  }, [showPublicNavbar, isOnFullScreenRoute])

  return (
    <>
      <AuthenticatedBar
        isAuthenticated={!showPublicNavbar}
        width={widthOffset()}
      />

      <Container marginLeft={widthOffset()}>{children}</Container>
    </>
  )
}

export default NavBar
