import React from 'react'
import styled from 'styled-components'
import { mediaQuery } from '../styles'
import useWindowDimensions from '../hooks/useWindowDimensions'
import LogoSymbol from './Svg/LogoSymbol'
import ArrowBack from './Svg/ArrowBack'
import FooterLinks from './Navigation/FooterLinks'

const LOGO_SIZE = 42
const PADDING = 10

export const LOGO_CONTAINER_SIZE = LOGO_SIZE + PADDING * 4

const Wrapper = styled.div<{ left: boolean; marginOffset: boolean }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 20px;
  padding: ${PADDING * 3}px 0px ${PADDING}px 0px;

  ${mediaQuery.tablet} {
    position: absolute;
    justify-content: ${({ left }) => (left ? 'flex-start' : 'center')};
    top: 0px;
  }
`

const SvgWrapper = styled.div`
  position: relative;
`

const LogoWrapper = styled.div`
  svg > path {
    fill: #fff;
  }
`

const BackWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 10px;
  left: -60px;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s;

  ${mediaQuery.tablet} {
    left: -80px;
  }
`

const WrapperContainerInner = styled.div`
  height: 100%;
  width: 100%;
`

const WrapperContainer = styled.div<{ marginOffset: boolean }>`
  margin: 0 auto;
  height: ${({ marginOffset }) => {
    return marginOffset ? '100%' : `calc(100% - ${LOGO_CONTAINER_SIZE}px)`
  }};
  max-height: 100%;
  width: 100%;
  padding: ${PADDING * 2}px 0px;

  ${mediaQuery.tablet} {
    padding: ${LOGO_CONTAINER_SIZE}px 0px 0px 0px;
  }
`

const WrapperOverflow = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${mediaQuery.tablet} {
    overflow: visible;
    justify-content: center;
    align-items: center;
  }
`

const Container = styled.div<{
  isBetween: boolean
  minHeight: boolean
  height: number
}>`
  display: flex;
  min-height: ${({ minHeight, height }) =>
    minHeight ? `${height}px` : 'auto'};
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: ${({ isBetween }) =>
    isBetween ? 'space-between' : 'flex-start'};
  position: relative;

  ${mediaQuery.tablet} {
    min-height: 100%;
  }
`

interface Props {
  onBack?: () => void
  isBackVisible?: boolean
  left?: boolean
  marginOffset?: boolean
  children: React.ReactNode
  footer?: boolean
  minHeight?: boolean
}

const HeaderNavigation: React.SFC<Props> = ({
  onBack,
  isBackVisible,
  left = false,
  marginOffset = false,
  children,
  footer = false,
  minHeight = true, // takes up entire screen as min height
}): React.ReactElement => {
  const { height } = useWindowDimensions()

  const handleOnBackClick = React.useCallback((): void => {
    if (onBack) onBack()
  }, [onBack])

  return (
    <Container isBetween={footer} minHeight={minHeight} height={height}>
      <Wrapper left={left} marginOffset={marginOffset}>
        <SvgWrapper>
          <BackWrapper
            isVisible={
              isBackVisible !== undefined ? isBackVisible : Boolean(onBack)
            }
            onClick={handleOnBackClick}
          >
            <ArrowBack fill="rgba(255, 255, 255, 0.5" />
          </BackWrapper>

          <LogoWrapper>
            <LogoSymbol size={LOGO_SIZE} />
          </LogoWrapper>
        </SvgWrapper>
      </Wrapper>

      <WrapperContainer marginOffset={marginOffset}>
        <WrapperContainerInner>
          <WrapperOverflow>{children}</WrapperOverflow>
        </WrapperContainerInner>
      </WrapperContainer>

      {footer ? <FooterLinks absolute /> : null}
    </Container>
  )
}

export default HeaderNavigation
