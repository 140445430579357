import * as React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { colors, variables } from '../../../styles'

const Wrapper = styled.div<SpaceProps>`
  width: 100%;
  padding: 0 12px;

  border: 1px solid #eaeaea;
  height: 35px;
  border-radius: ${variables.borderRadius};

  display: flex;
  align-items: center;

  ${space};

  & > div {
    width: 100%;
  }
`

const CreditCard: React.FC = () => {
  return (
    <Wrapper>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '0.875rem',
              fontWeight: 'normal',
              lineHeight: '17px',
              color: colors.primaryText,
              '::placeholder': {
                color: colors.placeholder,
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
    </Wrapper>
  )
}

export default CreditCard
