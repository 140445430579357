import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'

const Button = styled.div`
  height: 18px;
  width: 18px;
  cursor: pointer;
  border: 0;
  background: none;
  padding: 0;
`

const Dot = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) =>
    isActive ? colors.white : 'rgba(255, 255, 255, 0.5)'};
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 5px;
`

interface Props {
  active: boolean
  index: number
  onClick: (indx: number) => void
}

const PaginationDot: React.SFC<Props> = ({
  active,
  index,
  onClick,
}): React.ReactElement => {
  const handleClick = () => {
    onClick(index)
  }

  return (
    <Button onClick={handleClick}>
      <Dot isActive={active} />
    </Button>
  )
}

export default PaginationDot
