import config from '../config'
import logger from './logger'

let segment: any | null = null

if (typeof window !== 'undefined' && config.env !== 'development') {
  segment = window.analytics
}

export const trackPageUrl = (url: string): void => {
  logger.info(`Tracking page: ${url}`)

  if (segment) {
    segment.page(url)
  }
}

export const trackEvent = (event: string, fields: object = {}): void => {
  logger.info(`Tracking event: ${event}, fields: ${JSON.stringify(fields)}`)

  if (segment) {
    segment.track(event, { ...fields })
  }
}
