"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const phoneNumber = '123-456-7899';
exports.SendSmsCodeMutationMockHappy = {
    sendSmsCode: true,
    variables: {
        phoneNumber,
    },
};
exports.SendSmsCodeMutationMockSad = {
    sendSmsCode: false,
    variables: {
        phoneNumber,
    },
};
exports.SignInIdTokenMutationMockHappy = {
    signInIdToken: {
        __typename: 'SignInResult',
        accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOWY1MzQxNS1lZjgwLTQ2OTktOTc2OC0yYzI5MDQ3YjQyMmIiLCJyb2xlcyI6WyJBRE1JTiJdLCJyZWZyZXNoVG9rZW5JRCI6IjAxMDYwZmVlLTZlMTktNDBlNS1iNzA2LTBiNWE1NmEyZmU1MSIsImlzQWN0aXZlIjp0cnVlLCJpYXQiOjE1OTAwMjMwMDksImV4cCI6MTU5MDAyNjYwOX0.SsantOa2PLJtHc3E6HDgZgsO74JnSypH3mrXLherLSI',
        refreshToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOWY1MzQxNS1lZjgwLTQ2OTktOTc2OC0yYzI5MDQ3YjQyMmIiLCJpYXQiOjE1OTAwMjMwMDksImV4cCI6MTYyMTU1OTAwOX0.VGEjsIEsM7AeJkY3QSZjwLt6GDG1O0qwbxcxrN0TBHM',
        user: {
            __typename: 'User',
            acceptedTerms: true,
            email: null,
            id: '19f53415-ef80-4699-9768-2c29047b422b',
            intercomUserHash: '87ee019e34a30970222f4377f897f1e196dde1aaec3995fa6507716ffd2c2170',
            isActive: true,
            name: 'Jackie Wung',
            organizationMemberships: [],
        },
    },
};
exports.SignInPhoneNumberMutationMockHappy = {
    signInPhoneNumber: {
        __typename: 'SignInResult',
        accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpZZZZZ.eyJzdWIiOiI2ZWZlZDY5MS00YzBiLTQwNzUtYmY2MS1hNGFjNDRlYjBkZWYiLCJyb2xlcyI6WyJBRE1JTiJdLCJyZWZyZXNoVG9rZW5JRCI6ImI1YjNjOGI4LWRhNDUtNGU4Ny05Mjk1LTVkMDExMjc4MTAzMyIsImlhdCI6MTU2OTQ2NDMzMywiZXhwIjoxNTY5NDY3OTMzfQ.C5VIfaMxSnf3ahk0kl7BpDENtORiRrUpGe0BT-avRYQ',
        refreshToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpZZZZZ.eyJzdWIiOiI2ZWZlZDY5MS00YzBiLTQwNzUtYmY2MS1hNGFjNDRlYjBkZWYiLCJpYXQiOjE1Njk0NjQzMzMsImV4cCI6MTYwMTAwMDMzM30._fh4HvkYxUjrkSLrGh3wzUJRtjbCoKjVT-B0orl_OeU',
        user: {
            __typename: 'User',
            id: '6efed691-4c0b-4075-bf61-a4ac44eb0def',
            name: 'Jackie Wung',
        },
    },
    variables: {
        phoneNumber,
    },
};
exports.SignInPhoneNumberMutationMockSad = {
    signInPhoneNumber: null,
    variables: {
        phoneNumber,
    },
};
